/* eslint-disable */
import { LoadingManager, Mesh, MeshBasicMaterial, SphereBufferGeometry, BufferGeometry, Points, PointsMaterial, Vector3, Raycaster, Vector2, Sphere, PlaneGeometry, ShadowMaterial, Group, ShaderMaterial, Color, DoubleSide, BackSide, BoxBufferGeometry, Quaternion, Matrix4, Euler, TextureLoader, AnimationMixer, PCFSoftShadowMap, PCFShadowMap, CustomBlending, MaxEquation, FrontSide, PlaneBufferGeometry, Float32BufferAttribute, DstAlphaFactor } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import getNamedObjects from '../utils/getNamedObjects';
import ARPipeline from "./ARPipeline";
import app from '../global';
import Interaction from '../utils/Interaction';
import starCoords from './starData';
import basicVert from './shaders/basic-vert.glsl';
import gridFrag from './shaders/grid-frag.glsl';
import moonGlowVert from './shaders/moonGlow-vert.glsl';
import moonGlowFrag from './shaders/moonGlow-frag.glsl';
import moonAlphamaskPars from './shaders/moon-alphamask-pars.glsl';
import moonAlphamaskMain from './shaders/moon-alphamask-main.glsl';
import raysVert from './shaders/rays-vert.glsl';
import raysInnerFrag from './shaders/raysInner-frag.glsl';
import confettiVert from './shaders/confetti-vert.glsl';
import confettiFrag from './shaders/confetti-frag.glsl';
import pubsub from '../utils/pubsub';
import MoonParticles from '../widgets/Particles/MoonParticles';
import ARConfetti from "./ARConfetti";
import { gsap } from 'gsap/all';
import { DirectionalLight } from "three";
import { MeshStandardMaterial } from "three";
import { CubicBezierCurve3 } from "three";
import { CameraHelper } from "three";
import sfx from "../utils/sfx";
import pad from '../utils/pad'; // this is initially going to be written to use 8th wall but in theory could
// be swapped out with a roll-you-own gyro focused approach

var instance;
var arpipeline = ARPipeline.getInstance();
var moonParticles = MoonParticles.getInstance();
var SCREEN_WIDTH = window.innerWidth;
var SCREEN_HEIGHT = window.innerHeight;
var projectVec = new Vector3();
var cameraFront = new Vector3();
var currTime = Date.now();
var prevTime = currTime;
var sceneScale = 1;
var moonMainScale = 1;
var wreckageScale = 40;
var swarmFromMoonScale = 1;
var flyToShuttleScale = 6;
var earthCrashScale = 0.35;
var sunCompareScale = 1 / 100;
var sunMinScale = 0.00001;
var transitionDuration = 1.5;
var transitionEase = "power4.out";
var dirLightPosition = new Vector3(-50, -50, 10);
var moonHotspots = [{
  lat: -17,
  long: -130,
  radius: 0.5,
  id: "dossier"
}, {
  lat: 42,
  long: -5,
  radius: 0.5,
  id: "bagel"
}, {
  lat: -17,
  long: 58,
  radius: 0.5,
  id: "cloud"
}, {
  lat: 10,
  long: 20,
  radius: 1,
  id: "tickets"
} // {lat:-45, long:45, id:"cloud"}
// {lat:45, long:110, id:"cloud"}
]; // from https://stackoverflow.com/questions/28365948/javascript-latitude-longitude-to-xyz-position-on-earth-threejs/28367325

function calcPosFromLatLonRad(lat, lon, rad) {
  var phi = (90 - lat) * (Math.PI / 180);
  var theta = (lon + 180) * (Math.PI / 180);
  var x = -(rad * Math.sin(phi) * Math.cos(theta));
  var z = rad * Math.sin(phi) * Math.sin(theta);
  var y = rad * Math.cos(phi);
  return [x, y, z];
}

function screenPxFrom3dPos(camera, ptA) {
  var zDir = projectVec.copy(ptA).sub(camera.position).dot(cameraFront) > 0 ? 1 : -1; // console.log(projectVec.copy(ptA).sub(camera.position).dot(cameraFront));
  // if(projectVec.copy(ptA).sub(camera.position).dot(cameraFront) < 0){
  //   return [-1000, -1000];
  // }
  // var vec = new Vector3().copy(ptA).project(camera);

  projectVec.copy(ptA).project(camera);
  return [SCREEN_WIDTH * (projectVec.x * 0.5 + 0.5), SCREEN_HEIGHT * (-projectVec.y * 0.5 + 0.5), zDir];
}

function fovFromProjectionMatrix(projectionMatrix) {
  // return (180/Math.PI)*2*Math.atan(1/(2*projectionMatrix.elements[5]));
  return 180 / Math.PI * 2 * Math.atan(1 / projectionMatrix.elements[5]);
}

function unitHeightAtDistance(camera, distance) {
  var unitHeight = new Vector3(0, 100, -distance).applyMatrix4(camera.projectionMatrix); // console.log(unitHeight);
  // console.log(new Vector3(0, 1, distance).applyMatrix4(camera.projectionMatrix));

  return unitHeight.y * SCREEN_HEIGHT / 100; // return (unitHeight.y/unitHeight.z)*SCREEN_HEIGHT;
  // return (unitHeight.x)*SCREEN_WIDTH/100;
}

class ARScene {
  constructor() {
    this.initialized = false;
  }

  init(callback) {
    // arpipeline.init(() => {
    var loadManager = new LoadingManager();
    var xrscene = arpipeline.xrScene();
    this.renderer = xrscene.renderer;
    this.scene = xrscene.scene;
    this.camera = xrscene.camera;
    this.events = pubsub.getInstance();
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap; // default THREE.PCFShadowMap

    this.resetStateFunctions();
    this.interactions = new Interaction({
      element: document.body,
      onDown: (x, y) => {
        if (this.onDownFn) this.onDownFn(x, y);
      },
      onUp: (x, y, dragInfo) => {
        if (this.onUpFn) this.onUpFn(x, y, dragInfo);
      },
      onDrag: (x, y, dX, dY) => {
        if (this.onDragFn) this.onDragFn(x, y, dX, dY);
      } // onMove: (x, y) => { if(this.onMoveFn) this.onMoveFn(x, y)},
      // onKeyDown: (keyCode, arrows) => { if(this.onKeyDownFn) this.onKeyDownFn(keyCode, arrows)},
      // onMouseWheel: (event) => { if(this.onMouseWheelFn) this.onMouseWheelFn(event)}

    });
    this.interactions.addListeners();
    this.raycaster = new Raycaster();
    this.raycastPt = new Vector2();
    this.raycastIntersectPt = new Vector3(); // this.surfaceShadowMat = new ShadowMaterial({opacity: 0.5});

    this.surfaceGridMat = new ShaderMaterial({
      vertexShader: basicVert,
      fragmentShader: gridFrag,
      transparent: true
    });
    this.surface = new Mesh(new PlaneGeometry(100, 100, 1, 1), this.surfaceGridMat);
    this.surface.rotateX(-Math.PI / 2);
    this.surface.position.set(0, 0, 0);
    this.surface.receiveShadow = true;
    this.surface.visible = false;
    this.scene.add(this.surface);
    this.sceneOuter = new Group();
    this.sceneInner = new Group(); // this.sceneInner.add(new Mesh(new SphereBufferGeometry(0.1, 0.1, 6, 6), new MeshBasicMaterial({color:new Color(0xFF0000)})));

    this.scene.add(this.sceneOuter);
    this.sceneOuter.add(this.sceneInner);
    this.dirLight = new DirectionalLight();
    this.dirLight.castShadow = true;
    this.dirLight.position.copy(dirLightPosition);
    this.dirLight.shadow.bias = -0.001;
    var dirLightShadowHelper = new CameraHelper(this.dirLight.shadow.camera);
    this.camera.add(this.dirLight);
    this.camera.add(this.dirLight.target); // this.scene.add(dirLightShadowHelper)
    // this.dirLight.shadow.mapSize.width = 1024; // default
    // this.dirLight.shadow.mapSize.height = 1024; // default
    // light.shadow.camera.near = 0.5; // default
    // light.shadow.camera.far = 500; // default
    // var shadowSize = 10;
    // this.dirLight.shadow.camera.left = this.dirLight.shadow.camera.bottom = -shadowSize;
    // this.dirLight.shadow.camera.right = this.dirLight.shadow.camera.top = shadowSize;
    // add debug axis visualizers
    // var xAxisMesh = new Mesh(new BoxBufferGeometry(3, .1, .1), new MeshBasicMaterial({ color:new Color(0xFF0000)}));
    // this.sceneOuter.add(xAxisMesh);
    // var yAxisMesh = new Mesh(new BoxBufferGeometry(.1, 3, .1), new MeshBasicMaterial({ color:new Color(0x00FF00)}));
    // this.sceneOuter.add(yAxisMesh);
    // var zAxisMesh = new Mesh(new BoxBufferGeometry(.1, .1, 3), new MeshBasicMaterial({ color:new Color(0x0000FF)}));
    // this.sceneOuter.add(zAxisMesh);
    // this.moonTestTex = new TextureLoader(loadManager).load( `${app.site_path}assets/models/moon-testDots.jpg`, tex => {tex.flipY = false} );

    this.moonDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonNormalTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-normal.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonAlphaTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-alpha.jpg"), tex => {
      tex.flipY = false;
    });
    this.sunTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/sun-texture-1024.jpg"), tex => {
      tex.flipY = false;
    }); // this.debrisTex = new TextureLoader(loadManager).load( `${app.site_path}assets/models/debris.jpg`, tex => {tex.flipY = false} );

    this.bagelDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/bagel-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.bagelNormalTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/bagel-normal.jpg"), tex => {
      tex.flipY = false;
    });
    this.earthDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/earth-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.earthRoughnessTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/earth-roughness.jpg"), tex => {
      tex.flipY = false;
    });
    this.shuttleDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/shuttle-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonNoiseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/noise.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonMat = new MeshStandardMaterial({
      map: this.moonDiffuseTex,
      normalMap: this.moonNormalTex,
      alphaMap: this.moonAlphaTex,
      // emissive: new Color(0xFFFFFF),
      // emissiveMap: this.moonTestTex,
      side: FrontSide,
      transparent: true
    });
    this.moonMatSplitOpen = new MeshStandardMaterial({
      map: this.moonDiffuseTex,
      normalMap: this.moonNormalTex,
      // emissive: new Color(0xFFFFFF),
      // emissiveMap: this.moonTestTex,
      side: DoubleSide
    });
    this.moonMatUnlit = new MeshBasicMaterial({
      map: this.moonDiffuseTex
    });
    this.moonGlowMat = new ShaderMaterial({
      vertexShader: moonGlowVert,
      fragmentShader: moonGlowFrag,
      transparent: true,
      side: BackSide
    });
    this.moonDissolve = {
      value: 1
    };
    this.moonMatDissolve = new MeshStandardMaterial({
      map: this.moonDiffuseTex,
      normalMap: this.moonNormalTex,
      alphaMap: this.moonAlphaTex,
      transparent: true,
      onBeforeCompile: shader => {
        shader.uniforms.maskVal = this.moonDissolve;
        shader.uniforms.maskMap = {
          value: this.moonNoiseTex
        };
        this.moonMatDissolveUniforms = shader.uniforms;
        shader.fragmentShader = shader.fragmentShader.replace("#include <clipping_planes_pars_fragment>", "#include <clipping_planes_pars_fragment>\n" + moonAlphamaskPars);
        shader.fragmentShader = shader.fragmentShader.replace("#include <alphamap_fragment>", "#include <alphamap_fragment>\n" + moonAlphamaskMain);
      }
    });
    this.moonGlowMatDissolve = new ShaderMaterial({
      uniforms: {
        maskVal: this.moonDissolve,
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      vertexShader: moonGlowVert,
      fragmentShader: moonGlowFrag,
      transparent: true,
      side: BackSide,
      defines: {
        IS_DISSOLVE: true
      }
    });
    this.sunMat = new MeshBasicMaterial({
      map: this.sunTex
    }); // this.debrisMat = new MeshBasicMaterial({map:this.debrisTex, side: DoubleSide});

    this.bagelMat = new MeshStandardMaterial({
      map: this.bagelDiffuseTex,
      normalMap: this.bagelNormalTex,
      roughness: 0.5
    });
    this.earthMat = new MeshStandardMaterial({
      map: this.earthDiffuseTex,
      roughnessMap: this.earthRoughnessTex,
      roughness: 0.5
    });
    this.shuttleMat = new MeshStandardMaterial({
      map: this.shuttleDiffuseTex,
      roughness: 0.5,
      emissiveMap: this.shuttleDiffuseTex,
      emissive: new Color(0x111111)
    });
    this.raysInnerMat = new ShaderMaterial({
      uniforms: {
        maskVal: {
          value: 0
        },
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      blending: CustomBlending,
      blendEquation: MaxEquation,
      vertexShader: raysVert,
      fragmentShader: raysInnerFrag,
      transparent: true,
      side: DoubleSide,
      depthWrite: false,
      onBeforeCompile: shader => {
        shader.uniforms.maskVal = this.moonDissolve; // shader.uniforms.maskMap = {value:this.moonNoiseTex};
        // this.moonMatDissolveUniforms = shader.uniforms;
        // shader.fragmentShader = shader.fragmentShader.replace("#include <clipping_planes_pars_fragment>", "#include <clipping_planes_pars_fragment>\n"+moonAlphamaskPars);
        // shader.fragmentShader = shader.fragmentShader.replace("#include <alphamap_fragment>", "#include <alphamap_fragment>\n"+moonAlphamaskMain);
      }
    });
    this.raysOuterMat = new ShaderMaterial({
      uniforms: {
        maskVal: {
          value: 0
        },
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      blending: CustomBlending,
      blendEquation: MaxEquation,
      vertexShader: raysVert,
      fragmentShader: raysInnerFrag,
      transparent: true,
      side: DoubleSide,
      depthWrite: false,
      defines: {
        IS_OUTER: true
      },
      onBeforeCompile: shader => {
        shader.uniforms.maskVal = this.moonDissolve; // shader.uniforms.maskMap = {value:this.moonNoiseTex};
        // this.moonMatDissolveUniforms = shader.uniforms;
        // shader.fragmentShader = shader.fragmentShader.replace("#include <clipping_planes_pars_fragment>", "#include <clipping_planes_pars_fragment>\n"+moonAlphamaskPars);
        // shader.fragmentShader = shader.fragmentShader.replace("#include <alphamap_fragment>", "#include <alphamap_fragment>\n"+moonAlphamaskMain);
      }
    }); // this.scene.add(sphereMesh);

    window.arscene = this;
    var pointGeo = new BufferGeometry();
    var starPositions = [];

    for (var i = 0; i < starCoords.length; i += 2) {
      var sp = calcPosFromLatLonRad(starCoords[i], starCoords[i + 1], 900);
      starPositions.push(sp[0], sp[1], sp[2]);
    }

    pointGeo.setAttribute('position', new THREE.Float32BufferAttribute(starPositions, 3));
    pointGeo.computeBoundingSphere();
    var pointMaterial = new PointsMaterial({
      size: 5,
      sizeAttenuation: true
    });
    var points = new Points(pointGeo, pointMaterial);
    this.scene.add(points); // const loader = new GLTFLoader(loadManager);
    // new GLTFLoader(loadManager).load(
    //   `${app.site_path}assets/models/debris.glb`,
    //   (gltf) => {
    //     // console.log('DEBRIS LOADED!', gltf);
    //     // this.swapMaterials.call(this, gltf.scene);
    //     this.debris = getNamedObjects(gltf.scene, {}, true);
    //     for(var id in this.debris){
    //       this.debris[id].material = this.debrisMat;
    //     }
    //     // this.scene.add( gltf.scene );
    //   }
    // );

    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/earthCrash_v2.glb"), gltf => {
      this.swapMaterials.call(this, gltf.scene);
      this.earthCrashGltf = gltf;

      var _objects = getNamedObjects(gltf.scene, {}, true);

      _objects["Earth"].receiveShadow = true;
      _objects["Moon_Split_Left"].castShadow = true;
      _objects["Moon_Split_Right"].castShadow = true;
      _objects["Moon_Split_Left"].receiveShadow = true;
      _objects["Moon_Split_Right"].receiveShadow = true;
      _objects["Bagel"].castShadow = true;
      _objects["Bagel"].receiveShadow = true;

      var _confetti = ARConfetti.get();

      console.log(_confetti);
      this.confettiMesh = _confetti.mesh;
      this.confettiMat = _confetti.material;
      gltf.scene.add(this.confettiMesh);
    });
    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/shuttle.glb"), gltf => {
      var _objects = getNamedObjects(gltf.scene, {}, true);

      this.shuttleMesh = _objects['shuttle'];
      this.shuttleMesh.material = this.shuttleMat;
    });
    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/lightRays_v2.glb"), gltf => {
      console.log('LIGHTRAYS LOADED!'); // this.swapMaterials.call(this, gltf.scene);

      var _objects = getNamedObjects(gltf.scene, {}, true);

      this.moonRaysInnerA = _objects['Rays_Inner_A'];
      this.moonRaysInnerB = _objects['Rays_Inner_B'];
      this.moonRaysOuterA = _objects['Rays_Outer_A'];
      this.moonRaysOuterB = _objects['Rays_Outer_B'];
      this.moonRaysInnerA.renderOrder = this.moonRaysInnerB.renderOrder = 3;
      this.moonRaysOuterA.renderOrder = this.moonRaysOuterB.renderOrder = 4;
      this.moonRaysInnerA.material = this.raysInnerMat;
      this.moonRaysInnerB.material = this.raysInnerMat;
      this.moonRaysOuterA.material = this.raysOuterMat;
      this.moonRaysOuterB.material = this.raysOuterMat;
    });

    loadManager.onLoad = () => {
      this.initialized = true; // this.events.publish('initialized');

      window.addEventListener('resize', this.resize.bind(this));
      window.addEventListener('onorientationchange', this.resize.bind(this));
      this.resize(); // this.showFindMoon();
      // this.updateLoop();

      arpipeline.events.subscribe('render', () => {
        this.updateLoop();
      });
      if (callback) callback();
    }; // });

  }

  swapMaterials(Scene) {
    var getmaterial = material => {
      switch (material.name.toLowerCase()) {
        case 'earth':
          return this.earthMat;

        case 'moon':
          return this.moonMatSplitOpen;

        case 'bagel':
          return this.bagelMat;

        default:
          return material;
      }
    };

    var checkChildren = obj => {
      var children = obj.children;

      for (var i = 0; i < children.length; i++) {
        var child = children[i];

        if (child.material) {
          child.material = getmaterial(child.material);
        }

        if (child.materials) for (var m = 0; m < child.materials.length; m++) {
          child.materials[m] = getmaterial(child.materials[m]);
        }
        if (child.children.length) checkChildren(child);
      }
    };

    checkChildren(Scene);
  }

  updateLoop() {
    cameraFront.set(-this.camera.matrix.elements[8], -this.camera.matrix.elements[9], -this.camera.matrix.elements[10]); // this.camera.projectionMatrixInverse.copy( this.camera.projectionMatrix ).invert();

    currTime = Date.now();
    var elapsedTime = currTime - prevTime;
    prevTime = currTime;
    if (this.stateUpdateFn) this.stateUpdateFn(currTime, elapsedTime); // window.requestAnimationFrame(this.updateLoop.bind(this));

    this.events.publish('update', {
      delta: elapsedTime
    });
  }

  resetStateFunctions() {
    // console.log('resetStateFunctions');
    this.stateUpdateFn = null;
    this.onDownFn = null;
    this.onUpFn = null;
    this.onDragFn = null;
  }

  resize() {
    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight;
  } // SCENE TRANSFORM HELPERS
  // this will give us the adjust rotation that the sceneCenter will
  // show the passed rotation value to the camera, factoring in current 
  // camera and scene positions


  sceneAdjustedRotation(eulerRotation, nullY) {
    // first we get the matrix for default rotation relative to camera
    var _m1 = new Matrix4();

    if (nullY) {
      var yNullVector = new Vector3(1, 0, 1);

      _m1.lookAt(this.sceneOuter.position.clone().multiply(yNullVector), this.camera.position.clone().multiply(yNullVector), this.camera.up);
    } else {
      _m1.lookAt(this.sceneOuter.position, this.camera.position, this.camera.up);
    } // now we apply the passed euler


    var destRotationMatrix = new Matrix4().makeRotationFromEuler(eulerRotation);

    _m1.multiply(destRotationMatrix); // save current quat


    var quat_out = new Quaternion().setFromRotationMatrix(_m1); // calculate scene outer's world rotate and premultiply it with our quaternion

    _m1.extractRotation(this.sceneOuter.matrixWorld);

    var quat_parent = new Quaternion().setFromRotationMatrix(_m1);
    quat_out.premultiply(quat_parent.invert());
    return quat_out;
  } // ----------- MOON LANDING -----------


  initMoonLanding() {
    this.moonLandingInitiated = true;
    this.moonLandingGroup = new Group();
    this.moonLandingMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonMatDissolve);
    this.moonLandingMesh.renderOrder = 2;
    this.moonLandingInnerMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonGlowMatDissolve);
    this.moonLandingInnerMesh.renderOrder = 1;
    this.moonLandingGroup.add(this.moonLandingMesh);
    this.moonLandingGroup.add(this.moonLandingInnerMesh);
    this.moonLandingGroup.position.set(0, 0, 0);
    this.moonLandingGroup.rotation.y = -1.15;
    this.moonLandingGroup.rotation.x = 1;
    this.moonLandingGroup.add(this.moonRaysInnerA);
    this.moonLandingGroup.add(this.moonRaysInnerB);
    this.moonLandingGroup.add(this.moonRaysOuterA);
    this.moonLandingGroup.add(this.moonRaysOuterB); // this.moonLandingGroup.scale.setScalar(0);

    this.moonLandingPosition = {
      x: 0,
      y: 0,
      z: -100
    };
  }

  positionMoonLanding(x, y, z) {
    if (!this.moonLandingInitiated) this.initMoonLanding();
    this.moonLandingPosition.x = x;
    this.moonLandingPosition.y = y;
    this.moonLandingPosition.z = z;
  }

  showMoonLanding() {
    return new Promise(resolve => {
      if (!this.moonLandingInitiated) this.initMoonLanding();
      this.scene.add(this.moonLandingGroup);
      this.updateLoop();

      this.stateUpdateFn = (currTime, elapsedTime) => {
        this.camera.updateMatrixWorld(); // need for unproject to work properly

        this.moonLandingGroup.position.set(this.moonLandingPosition.x, this.moonLandingPosition.y, this.moonLandingPosition.z).unproject(this.camera);
      };

      window.setTimeout(() => {
        gsap.to(this.moonDissolve, {
          value: 0,
          duration: 2,
          ease: "power2.inout"
        });
        resolve();
      }, 1000);
    });
  }

  hideMoonLanding() {
    return new Promise(resolve => {
      var tl = gsap.timeline({
        paused: true,
        onComplete: () => {
          // gsap.to(this.moonLandingGroup.scale, {x:0, y:0, z:0, duration:0.5, ease:"power4.in", onComplete:()=>{
          this.resetStateFunctions();
          this.scene.remove(this.moonLandingGroup);
          resolve();
        }
      });
      tl.to(this.moonDissolve, {
        value: 1,
        duration: 1.5,
        ease: "power2.inOut"
      }, 0);
      tl.to(this.moonLandingGroup.scale, {
        x: 0.25,
        y: 0.25,
        z: 0.25,
        duration: 1,
        ease: "power4.in"
      }, 0);
      tl.play();
    });
  } // ----------- FIND MOON -----------


  initFindMoon() {
    this.findMoonInitiated = true;
    this.findMoonMesh = new Mesh(new SphereBufferGeometry(20, 16, 16), this.moonMatUnlit);
    this.findMoonMesh.position.set(100, 100, -100);
    this.findMoonMesh.scale.setScalar(0.5);
    this.findMoonSphere = new Sphere(this.findMoonMesh.position, 40);
  }

  showFindMoon() {
    // this is a dumb shortcut to place the moon
    if (app.debug_shortcut) {
      return new Promise(resolve => {
        if (!this.findMoonInitiated) this.initFindMoon();
        resolve();
        this.events.publish('moonAcquired');
        setTimeout(() => {
          this.events.publish('moonLockComplete');
        }, 3000);
      });
    }

    return new Promise(resolve => {
      if (!this.findMoonInitiated) this.initFindMoon();
      this.scene.add(this.findMoonMesh);
      var holdTime = 500;
      var holdDuration = 0;
      var moonAcquired = false;
      var cameraUp = new Vector3();
      var moonPos = new Vector3();
      var edgePos = new Vector3();

      var _v1 = new Vector3(); // this is a dumb shortcut to plac


      this.stateUpdateFn = (currTime, elapsedTime) => {
        this.findMoonMesh.updateWorldMatrix(true, false);
        moonPos.set(0, 0, 0).applyMatrix4(this.findMoonMesh.matrixWorld);
        var moonScreenPos = screenPxFrom3dPos(this.camera, moonPos);
        cameraUp.set(-this.camera.matrix.elements[4], -this.camera.matrix.elements[5], -this.camera.matrix.elements[6]);

        var moonWorldScale = _v1.setFromMatrixColumn(this.findMoonMesh.matrixWorld, 0).length();

        edgePos.copy(moonPos).addScaledVector(cameraUp, moonWorldScale * 20);
        var edgeScreenPos = screenPxFrom3dPos(this.camera, edgePos);
        var radius = new Vector2(moonScreenPos[0] - edgeScreenPos[0], moonScreenPos[1] - edgeScreenPos[1]).length(); // const moonDist = moonPos.clone().sub(this.camera.position).length();
        // const visibleHeight = moonDist*Math.tan((Math.PI/180)*fovFromProjectionMatrix(this.camera.projectionMatrix));
        // const radius = SCREEN_HEIGHT*(moonWorldScale*20)/visibleHeight

        this.events.publish('moonFindPosition', {
          center: moonScreenPos,
          width: radius * 2
        }); // for this mode we are just comparing to where the camera
        // is looking. So we just use the middle of the screen

        this.raycastPt.set(0, 0);
        this.raycaster.setFromCamera(this.raycastPt, this.camera);

        if (this.raycaster.ray.intersectSphere(this.findMoonSphere, this.raycastIntersectPt)) {
          if (holdDuration == 0) this.events.publish('moonFound');
          holdDuration += elapsedTime;

          if (holdDuration > holdTime) {
            if (!moonAcquired) this.events.publish('moonAcquired');
            moonAcquired = true;
          }
        } else {
          if (!moonAcquired) this.events.publish('moonLost');
          holdDuration = 0;
        }
      };

      resolve();
    });
  }

  hideFindMoon() {
    return new Promise(resolve => {
      // console.log('HIDE FIND MOON');
      this.resetStateFunctions();
      this.showMoonLock();
      resolve();
    });
  } // ----------- MOON LOCK -----------


  showMoonLock() {
    return new Promise(resolve => {
      var cameraUp = new Vector3();
      var moonPos = new Vector3();
      var edgePos = new Vector3();

      var _v1 = new Vector3();

      var bouncing = false;
      var completed = false;
      var screenVelX = 0;
      var screenVelY = 0;
      var xPos = 0;
      var yPos = 0;
      var zPos = 0;
      var xVel = 0;
      var yVel = 0;
      var radius = 0;
      var focalPlaneWidth = SCREEN_WIDTH;
      var focalPlaneHeight = SCREEN_HEIGHT;
      var camVecStart = new Vector3();
      var camVecFrame = new Vector3();
      var lockCalibrationMax = 3000;
      var lockCalibration = 0;
      var bounceReduction = 100;
      var velocityReduction = 1;

      this.onDownFn = this.onDragFn = (x, y) => {
        var xDif = x - xPos;
        var yDif = y - yPos;

        if (Math.abs(xDif) < radius && Math.abs(yDif) < radius) {
          if (!bouncing) {
            camVecStart.copy(this.findMoonMesh.position).project(this.camera);
            bouncing = true;
          }

          var diffDist = Math.sqrt(xDif * xDif + yDif * yDif);
          xDif = -xDif / diffDist;
          yDif = -yDif / diffDist;
          xVel = xDif * 1.5;
          yVel = yDif * 1.5;
        }
      };

      this.stateUpdateFn = (currTime, elapsedTime) => {
        if (completed) return;
        this.findMoonMesh.updateWorldMatrix(true, false);
        moonPos.set(0, 0, 0).applyMatrix4(this.findMoonMesh.matrixWorld);
        var moonScreenPos = screenPxFrom3dPos(this.camera, moonPos);
        cameraUp.set(-this.camera.matrix.elements[4], -this.camera.matrix.elements[5], -this.camera.matrix.elements[6]);

        var moonWorldScale = _v1.setFromMatrixColumn(this.findMoonMesh.matrixWorld, 0).length();

        edgePos.copy(moonPos).addScaledVector(cameraUp, moonWorldScale * 20);
        var edgeScreenPos = screenPxFrom3dPos(this.camera, edgePos);
        radius = new Vector2(moonScreenPos[0] - edgeScreenPos[0], moonScreenPos[1] - edgeScreenPos[1]).length();
        screenVelX = (moonScreenPos[0] - xPos) / elapsedTime;
        screenVelY = (moonScreenPos[1] - yPos) / elapsedTime;

        if (!bouncing) {
          // we are tied to the screen still, so directly apply screen velocity and position
          xVel = screenVelX;
          yVel = screenVelY;
          xPos = moonScreenPos[0];
          yPos = moonScreenPos[1];
          zPos = moonScreenPos[2];

          if (xPos < radius || xPos > SCREEN_WIDTH - radius || yPos < radius || yPos > SCREEN_HEIGHT - radius) {
            camVecStart.copy(this.findMoonMesh.position).project(this.camera);
            bouncing = true;
          }
        }

        if (bouncing) {
          var bounce = false; // first, if we are at the edge of the screen, we reverse velocity so it bounces

          if (xPos < radius) {
            bounce = true;
            xVel = Math.abs(xVel);
            xVel -= screenVelX;
            lockCalibration -= bounceReduction;
          } else if (xPos > SCREEN_WIDTH - radius) {
            bounce = true;
            xVel = -Math.abs(xVel);
            xVel -= screenVelX;
            lockCalibration -= bounceReduction;
          } else {
            xPos += screenVelX * elapsedTime;
          }

          if (yPos < radius) {
            bounce = true;
            yVel = Math.abs(yVel);
            yVel -= screenVelY;
            lockCalibration -= bounceReduction;
          } else if (yPos > SCREEN_HEIGHT - radius) {
            bounce = true;
            yVel = -Math.abs(yVel);
            yVel -= screenVelY;
            lockCalibration -= bounceReduction;
          } else {
            yPos += screenVelY * elapsedTime;
          }

          if (bounce) {
            sfx.playFx("MF_SX_Moon_Wall_Imp_".concat(pad(Math.ceil(Math.random() * 10), 2)));
          }

          xVel *= 0.97;
          yVel *= 0.97; // and finally apply our remaining velocity to the position

          xPos += xVel * elapsedTime;
          yPos += yVel * elapsedTime; // and update mesh's position to match

          this.camera.updateMatrixWorld(); // need for unproject to work properly

          this.findMoonMesh.position.set((xPos / SCREEN_WIDTH - 0.5) * 2, (0.5 - yPos / SCREEN_HEIGHT) * 2, camVecStart.z).unproject(this.camera);
        }

        this.events.publish('moonFindPosition', {
          center: [xPos, yPos, zPos],
          width: radius * 2
        });
        lockCalibration += elapsedTime;
        lockCalibration -= (Math.abs(xVel) + Math.abs(yVel)) * elapsedTime * velocityReduction;
        lockCalibration = Math.max(0, lockCalibration);
        this.events.publish('calibrationProgress', {
          progress: Math.min(1, Math.max(0, lockCalibration / lockCalibrationMax))
        });

        if (lockCalibration >= lockCalibrationMax) {
          completed = true;
          this.events.publish('moonLockComplete');
        }
      };

      resolve();
    });
  }

  hideMoonLock() {
    return new Promise(resolve => {
      resolve();
      this.scene.remove(this.findMoonMesh);
      this.resetStateFunctions();
      this.showPlaceMoon();
    });
  } // ----------- PLACE MOON -----------


  showPlaceMoon() {
    this.events.publish('showPlaceMoon');
    return new Promise(resolve => {
      this.onUpFn = (x, y, dragInfo) => {
        this.raycastPt.set(x / SCREEN_WIDTH * 2 - 1, y / SCREEN_HEIGHT * -2 + 1);
        this.raycaster.setFromCamera(this.raycastPt, this.camera);
        var intersects = this.raycaster.intersectObject(this.surface);

        if (intersects.length === 1 && intersects[0].object === this.surface) {
          this.sceneOuter.position.set(intersects[0].point.x, 1, intersects[0].point.z);
          sceneScale = this.sceneOuter.position.clone().sub(this.camera.position).length() / 1.5;
          this.sceneOuter.scale.setScalar(sceneScale);
          var diffVec = this.sceneOuter.position.clone().sub(this.camera.position).normalize();
          this.sceneOuter.rotation.y = Math.atan2(diffVec.x, diffVec.z);
          this.hidePlaceMoon();
          this.events.publish('moonPlaced');
        }
      };

      resolve();
    });
  }

  hidePlaceMoon() {
    return new Promise(resolve => {
      this.resetStateFunctions();
      this.showMoonMain();
      resolve();
    });
  } // ----------- MOON MAIN -----------


  initMoonMain() {
    this.moonMainInitiated = true;
    this.moonMainGroup = new Group();
    this.moonMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonMat);
    this.moonMesh.renderOrder = 2;
    this.moonInnerMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonGlowMat);
    this.moonInnerMesh.renderOrder = 1;
    this.moonMainGroup.add(this.moonMesh);
    this.moonMainGroup.add(this.moonInnerMesh);
    this.moonMainGroup.position.set(0, 0, 0);
    this.moonMainGroup.add(this.moonRaysInnerA);
    this.moonMainGroup.add(this.moonRaysInnerB);
    this.moonMainGroup.add(this.moonRaysOuterA);
    this.moonMainGroup.add(this.moonRaysOuterB); // this.moonMainGroup.scale.setScalar(1/40);

    this.moonMainGroup.scale.setScalar(0.0000000001);

    for (var i = 0; i < moonHotspots.length; i++) {
      var hotspotMesh = new Mesh(new SphereBufferGeometry(2, 6, 6), new MeshBasicMaterial({
        color: new Color(0xFF0000)
      }));
      var pos = calcPosFromLatLonRad(moonHotspots[i].lat, moonHotspots[i].long, moonHotspots[i].radius);
      hotspotMesh.position.set(pos[0], pos[1], pos[2]);
      hotspotMesh.visible = false;
      this.moonMainGroup.add(hotspotMesh);
      moonHotspots[i].mesh = hotspotMesh;
    }

    this.moonMainGroup.add(this.shuttleMesh);
    this.shuttleMesh.position.copy(moonHotspots[3].mesh.position);
    this.shuttleMesh.scale.setScalar(0.005); // this.shuttleMesh.castShadow = true;
    // this.shuttleMesh.receiveShadow = true;
    // this.debrisGroup = new Group;
    // for(var id in this.debris){
    //   this.debrisGroup.add(this.debris[id]);
    // }
    // this.debrisGroup.position.copy(moonHotspots[1].mesh.position);
    // this.moonMainGroup.add(this.debrisGroup);
    // this.debrisGroup.scale.setScalar(0.1/40);

    this.sceneInner.add(this.moonMainGroup);
    this.sceneOuter.updateMatrixWorld();
    this.sceneInner.updateMatrixWorld();
    var destRot = this.sceneAdjustedRotation(new Euler(-moonHotspots[3].lat * (Math.PI / 180), Math.PI / 2 + -moonHotspots[3].long * (Math.PI / 180), 0, "XYZ"), false);
    this.moonMainGroup.quaternion.copy(destRot);
  }

  showMoonMain() {
    return new Promise(resolve => {
      if (!this.moonMainInitiated) this.initMoonMain();
      moonParticles.show(); // var eulerRotation = new Euler();
      // this.sceneOuter.quaternion.copy(this.sceneAdjustedRotation(eulerRotation));

      var moonRotationX = 0;
      var moonRotationY = 0;
      var moonRotationSpeedX = 0;
      var moonRotationSpeedY = 0;
      var moonRotationPrevX = 0;
      var moonRotationPrevY = 0;
      var moonIsIn = false;
      var moonDragging = false;
      var rotationEuler = new Euler();
      var rotationQuaternion = new Quaternion();

      var rotateMoon = (dX, dY) => {
        // this.sceneInner.rotation.y += dY;
        // this.sceneInner.rotation.x += dX;
        rotationEuler.x = dX;
        rotationEuler.y = dY;
        rotationQuaternion.setFromEuler(rotationEuler); // rotationQuaternion.multiply(this.sceneInner.quaternion);
        // this.sceneInner.quaternion.copy(rotationQuaternion);

        rotationQuaternion.multiply(this.moonMainGroup.quaternion);
        this.moonMainGroup.quaternion.copy(rotationQuaternion);
      };

      this.stateUpdateFn = (currTime, elapsedTime) => {
        // this.dirLight.shadow.needsUpdate = true;
        this.shuttleMesh.rotation.y += elapsedTime * 0.00025;
        var hotspotScreenPos = [];
        var hotSpotWorldPos = new Vector3(); // let hotSpotDir = new Vector3();

        var moonWorldPos = this.moonMesh.getWorldPosition(new Vector3());
        var cameraDir = this.camera.position.clone().sub(moonWorldPos).normalize();

        for (var i = 0; i < moonHotspots.length; i++) {
          hotSpotWorldPos = moonHotspots[i].mesh.getWorldPosition(hotSpotWorldPos);
          var hotSpotScreenPos = screenPxFrom3dPos(this.camera, hotSpotWorldPos);
          var dotProd = hotSpotWorldPos.sub(moonWorldPos).normalize().dot(cameraDir);
          hotspotScreenPos.push({
            id: moonHotspots[i].id,
            center: hotSpotScreenPos,
            aligned: dotProd
          });
        }

        this.events.publish('updateHotspotPositions', {
          hotspots: hotspotScreenPos
        }); // good place to test the scene rotation functions
        // this.sceneInner.quaternion.setFromUnitVectors(new Vector3(0, 1, 0), new Vector3().copy(this.sceneInner.position).sub(this.camera.position).normalize())

        if (moonDragging) {
          moonRotationSpeedX = (moonRotationX - moonRotationPrevX) / elapsedTime * 1;
          moonRotationSpeedY = (moonRotationY - moonRotationPrevY) / elapsedTime * 1;
        } else {
          moonRotationSpeedX = moonRotationSpeedX * (1000 - elapsedTime) / 1000;
          moonRotationSpeedY = moonRotationSpeedY * (1000 - elapsedTime) / 1000;
        }

        moonRotationPrevX = moonRotationX;
        moonRotationPrevY = moonRotationY;

        if (moonIsIn) {
          // rotateMoon(0, moonRotationSpeedY*elapsedTime);
          // rotateMoon(moonRotationSpeedX*elapsedTime, 0);
          rotateMoon(moonRotationSpeedX * elapsedTime, moonRotationSpeedY * elapsedTime);
        }
      }; // lets animate to actual scale


      gsap.to(this.moonDissolve, {
        duration: transitionDuration,
        value: 0
      });
      gsap.to(this.moonMainGroup.position, {
        duration: transitionDuration,
        ease: transitionEase,
        x: 0,
        y: 0,
        z: 0
      });
      gsap.to(this.moonMainGroup.scale, {
        duration: transitionDuration,
        ease: transitionEase,
        x: 1,
        y: 1,
        z: 1,
        onComplete: () => {
          this.onDownFn = (x, y) => {
            // console.log('onDown!');
            moonDragging = true;
          };

          this.onUpFn = (x, y) => {
            // console.log('onUp!');
            moonDragging = false;
          };

          this.onDragFn = (x, y, dX, dY) => {
            // console.log('onDragFn', dX, dY);
            moonRotationX += -dY * 2 / SCREEN_WIDTH; // rotateMoon(-dY*2 / SCREEN_WIDTH, 0);

            moonRotationY += dX * 2 / SCREEN_WIDTH; // rotateMoon(0, dX*2 / SCREEN_WIDTH);

            rotateMoon(-dY * 2 / SCREEN_WIDTH, dX * 2 / SCREEN_WIDTH); // this.sceneInner.rotation.y += dX*2 / SCREEN_WIDTH;
          };

          moonIsIn = true;
          resolve();
        }
      }); //

      if (this.sunCompareGroup && this.sunCompareGroup.scale.x > sunMinScale) {
        gsap.to(this.sunCompareGroup.position, {
          duration: transitionDuration,
          ease: transitionEase,
          x: 0,
          y: -3,
          z: 0
        });
        gsap.to(this.sunCompareGroup.scale, {
          duration: transitionDuration,
          ease: transitionEase,
          x: sunMinScale,
          y: sunMinScale,
          z: sunMinScale,
          onComplete: () => {
            this.sceneInner.remove(this.sunCompareGroup);
          }
        });
      }
    });
  }

  hideMoonMain() {
    return new Promise(resolve => {
      this.resetStateFunctions();
      moonParticles.hide(); // this.sceneInner.remove(this.moonMainGroup);

      resolve();
    });
  } // ----------- SUN COMPARE -----------


  initSunCompare() {
    this.sunCompareInitiated = true;
    this.sunCompareGroup = new Group();
    this.sunMesh = new Mesh(new SphereBufferGeometry(200, 16, 16), this.sunMat);
    this.sunCompareGroup.add(this.sunMesh);
    this.sunCompareGroup.position.set(0, -3, 0);
    this.sunCompareGroup.scale.setScalar(sunMinScale);
  }

  showSunCompare() {
    return new Promise(resolve => {
      if (!this.sunCompareInitiated) this.initSunCompare();
      this.sceneInner.add(this.sunCompareGroup); // this.sceneInner.remove(this.moonMainGroup);

      this.stateUpdateFn = (currTime, elapsedTime) => {
        var sunScreenPos = screenPxFrom3dPos(this.camera, this.sunMesh.getWorldPosition(new Vector3()));
        var moonScreenPos = screenPxFrom3dPos(this.camera, this.moonMesh.getWorldPosition(new Vector3()));
        this.events.publish('updateSizeComparison', {
          sun: sunScreenPos,
          moon: moonScreenPos
        });
      };

      gsap.to(this.moonMainGroup.position, {
        duration: transitionDuration,
        ease: transitionEase,
        x: 0,
        y: 2.5,
        z: 0
      });
      gsap.to(this.moonMainGroup.scale, {
        duration: transitionDuration,
        ease: transitionEase,
        x: sunCompareScale,
        y: sunCompareScale,
        z: sunCompareScale,
        onComplete: resolve
      });
      gsap.to(this.sunCompareGroup.position, {
        duration: transitionDuration,
        ease: transitionEase,
        x: 0,
        y: -0.5,
        z: 0
      });
      gsap.to(this.sunCompareGroup.scale, {
        duration: transitionDuration,
        ease: transitionEase,
        x: sunCompareScale,
        y: sunCompareScale,
        z: sunCompareScale
      });
      resolve();
    });
  }

  hideSunCompare() {
    return new Promise(resolve => {
      this.resetStateFunctions(); // this.sceneInner.remove(this.sunCompareGroup);
      // this.sceneInner.add(this.moonMainGroup);

      resolve();
    });
  } // ----------- SWARM -----------


  initSwarmFromMoon() {
    this.swarmFromMoonInitiated = true;
    this.swarmFromMoonGroup = new Group();
    this.swarmFromMoonGroup.position.set(0, 1, 0);
    this.swarmFromMoonGroup.scale.setScalar(1);
    this.swarmFromMoonPath = new CubicBezierCurve3();
    this.swarmTestMesh = new Mesh(new SphereBufferGeometry(0.1, 5, 5), new MeshBasicMaterial({
      color: new Color(0x000000),
      side: DoubleSide
    })); // this.swarmTestMesh.visible = false;
  }

  showSwarmFromMoon() {
    return new Promise(resolve => {
      if (!this.swarmFromMoonInitiated) this.initSwarmFromMoon();
      this.sceneInner.add(this.swarmFromMoonGroup);
      this.moonMainGroup.localToWorld(this.swarmTestMesh.position.set(0, 0, 0));
      moonParticles.show(); // this.swarmTestMesh.visible = false;

      var startRot = this.moonMainGroup.quaternion.clone();
      var offset = 0.75;
      var destRot = this.sceneAdjustedRotation(new Euler(-moonHotspots[2].lat * (Math.PI / 180) - 0.5 * offset, Math.PI / 2 + -moonHotspots[2].long * (Math.PI / 180) - 0.25 * offset, 0, "XYZ"), false); // var destQuat = new Quaternion.
      // var destRot = this.sceneAdjustedRotation(new Euler(0, Math.PI/6, 0), false);

      var startEase = function startEase(x, t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t + b;
        return -c / 2 * (--t * (t - 2) - 1) + b;
      };

      var endEaseFn = function endEaseFn(x, t, b, c, d) {
        return c * (t /= d) * t + b;
      }; // const dirLightPosition = new Vector3(-50, -50, 10);


      gsap.to(this.dirLight.position, {
        duration: transitionDuration,
        ease: transitionEase,
        x: -10,
        y: -10,
        z: 10
      });
      var sceneQuat = this.moonMainGroup.quaternion;
      gsap.to({
        prog: 0
      }, {
        duration: transitionDuration,
        ease: transitionEase,
        prog: 1,
        onUpdate: function onUpdate() {
          sceneQuat.slerpQuaternions(startRot, destRot, this._targets[0].prog);
        },
        onComplete: () => {
          this.scene.add(this.swarmTestMesh);
          var movementProgress = 0;
          var movementDuration = 5000;

          this.stateUpdateFn = (currTime, elapsedTime) => {
            movementProgress += elapsedTime; // movementProgress %= movementDuration;

            if (movementProgress >= movementDuration) {
              movementProgress = movementDuration;

              this.stateUpdateFn = (currTime, elapsedTime) => {
                this.swarmTestMesh.position.copy(this.camera.position); // this.swarmTestMesh.visible = true;

                resolve();
              };
            } // first lets update the bezier curve to current positions
            // first the start and end points


            this.moonMainGroup.localToWorld(this.swarmFromMoonPath.v0.copy(moonHotspots[2].mesh.position));
            this.swarmFromMoonPath.v3.copy(this.camera.position);
            var distance = this.swarmFromMoonPath.v0.clone().sub(this.swarmFromMoonPath.v3).length(); // now the handles

            this.moonMainGroup.localToWorld(this.swarmFromMoonPath.v1.copy(moonHotspots[2].mesh.position).multiplyScalar(2));
            this.swarmFromMoonPath.v2.set(-this.camera.matrix.elements[8], -this.camera.matrix.elements[9], -this.camera.matrix.elements[10]);
            this.swarmFromMoonPath.v2.y += 0.2 + Math.sin(currTime / 200) * 0.1;
            this.swarmFromMoonPath.v2.x += Math.sin(currTime / 300) * 0.1;
            this.swarmFromMoonPath.v2.z += Math.cos(currTime / 250) * 0.1;
            this.swarmFromMoonPath.v2.multiplyScalar(distance).add(this.swarmFromMoonPath.v3);
            var t = movementProgress / movementDuration;
            var p;
            var pausePt = 0.7;
            var pauseTime = 0.4;

            if (t < pauseTime) {
              p = startEase(null, t / pauseTime, 0, 1, 1) * pausePt;
              this.swarmTestMesh.scale.setScalar(0.0001);
            } else {
              p = pausePt + endEaseFn(null, (t - pauseTime) / (1 - pauseTime), 0, 1, 1) * (1 - pausePt);
              this.swarmTestMesh.scale.setScalar(Math.max(0, (t - 0.95) / 0.05));
            } // p=t;


            this.swarmFromMoonPath.getPoint(p, this.swarmTestMesh.position);
            arpipeline.setCameraDistortion(0.2 + p * 2); // this.swarmTestMesh.position
          };
        }
      });
    });
  }

  hideSwarmFromMoon() {
    return new Promise(resolve => {
      this.dirLight.position.copy(dirLightPosition);
      arpipeline.setCameraDistortion(0);
      this.resetStateFunctions();
      this.scene.remove(this.swarmTestMesh);
      this.sceneInner.remove(this.swarmFromMoonGroup);
      resolve();
    });
  } // ----------- EARTH CRASH -----------


  initEarthCrash() {
    console.log('initEarthCrash!');
    this.earthCrashInitiated = true;
    this.earthCrashGroup = new Group();
    this.earthCrashGroup.position.set(0, 1, 0);
    this.earthCrashGroup.scale.setScalar(1);
    this.earthCrashGltf.scene;
    this.earthCrashAnim = {
      mixer: new AnimationMixer(this.earthCrashGltf.scene),
      duration: 0
    };
    var clips = this.earthCrashGltf.animations;
    clips.forEach(clip => {
      var action = this.earthCrashAnim.mixer.clipAction(clip);
      action.clampWhenFinished = true;
      action.play();
      this.earthCrashAnim.duration = Math.max(clip.duration, this.earthCrashAnim.duration);
    });
    this.earthCrashAnim.duration -= 1 / 30;
    this.earthCrashAnim.mixer.setTime(0);
    this.earthCrashGltf.scene.rotation.y = Math.PI / 2;
  }

  createConfetti() {
    // first a single piece
    var confettiPiece = new PlaneBufferGeometry(0.05, 0.1, 1, 1); // now we will create a bunch of copies of that and put it all into
    // a single geometry but with some custom individual value for
    // the vertex shader to use

    var numPieces = 256;
    var combinedGeo = new BufferGeometry();
    var indices = [];
    var vertices = [];
    var uvs = [];
    var destPosition = [];
    var destPositionPer = new Vector3();
    var destRotation = [];
    var destRotationPer = new Vector3();
    var colorA = [];
    var colorB = [];
    var confettiColors = [new Color(0xa864fd), new Color(0x29cdff), new Color(0x78ff44), new Color(0xff718d), new Color(0xfdff6a)];

    var randomVal = amp => {
      return amp * (1 - Math.random() * 2);
    };

    var addColor = (index, colorArray) => {
      var col = confettiColors[index];
      colorArray.push(col.r, col.g, col.b);
    };

    var colA, colB;

    for (var i = 0; i < numPieces; i++) {
      // destPositionPer.set(randomVal(10),randomVal(6),randomVal(6));
      // destRotationPer.set(randomVal(30),randomVal(30),randomVal(30));
      // colA = Math.floor(confettiColors.length*Math.random());
      // colB = Math.floor(confettiColors.length*Math.random());
      for (var _index = 0; _index < confettiPiece.index.array.length; _index++) {
        indices.push(confettiPiece.index.array[_index] + i * confettiPiece.index.array.length); // addColor(colA, colorA);
        // addColor(colB, colorB);
        // destPosition.push(destPositionPer.x, destPositionPer.y, destPositionPer.z);
        // destRotation.push(destRotationPer.x, destRotationPer.y, destRotationPer.z);
      }

      for (var _position = 0; _position < confettiPiece.attributes.position.array.length; _position++) {
        vertices.push(confettiPiece.attributes.position.array[_position]);
      }

      for (var _uv = 0; _uv < confettiPiece.attributes.uv.array.length; _uv++) {
        uvs.push(confettiPiece.attributes.uv.array[_uv]);
      }
    }

    combinedGeo.setIndex(indices);
    combinedGeo.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    combinedGeo.setAttribute('uv', new Float32BufferAttribute(uvs, 2)); // combinedGeo.setAttribute( 'destPosition', new Float32BufferAttribute( destPosition, 3 ) );
    // combinedGeo.setAttribute( 'destRotation', new Float32BufferAttribute( destRotation, 3 ) );
    // combinedGeo.setAttribute( 'colorA', new Float32BufferAttribute( colorA, 3 ) );
    // combinedGeo.setAttribute( 'colorB', new Float32BufferAttribute( colorB, 3 ) );
    // const confettiMat = new ShaderMaterial({
    //   uniforms: {
    //     progress:{value:0}
    //   },
    //   vertexShader: basicVert,
    //   fragmentShader: confettiFrag,
    //   side: DoubleSide
    // });

    var confettiMat = new MeshBasicMaterial({
      side: DoubleSide
    });
    var confettiMesh = new Mesh(combinedGeo, confettiMat);
    confettiMesh.rotation.y = Math.PI / 2;
    confettiMesh.frustumCulled = false;
    return {
      mesh: confettiMesh,
      material: confettiMat
    };
  }

  showEarthCrash() {
    return new Promise(resolve => {
      if (!this.earthCrashInitiated) this.initEarthCrash();
      var _transitionDuration = 1.5;
      var _transitionEase = "power4.inout";
      var startRot = this.moonMainGroup.quaternion.clone();
      var destRotMatrix = new Matrix4();
      var destRot = this.sceneAdjustedRotation(new Euler(0, 0, Math.PI / 2), true);
      var sceneQuat = this.moonMainGroup.quaternion;
      this.earthCrashAnim.mixer.setTime(0);
      gsap.to({
        prog: 0
      }, {
        duration: _transitionDuration,
        ease: _transitionEase,
        prog: 1,
        onUpdate: function onUpdate() {
          sceneQuat.slerpQuaternions(startRot, destRot, this._targets[0].prog);
        },
        onComplete: () => {
          console.log('start earth Crash animation!');
          this.sceneInner.add(this.earthCrashGltf.scene);
          this.sceneInner.remove(this.moonMainGroup);

          this.stateUpdateFn = (currTime, elapsedTime) => {
            if (this.earthCrashAnim.mixer.time + elapsedTime / 1000 >= this.earthCrashAnim.duration) {
              this.earthCrashAnim.mixer.setTime(this.earthCrashAnim.duration);
              this.stateUpdateFn = null;
              resolve();
            } else {
              this.earthCrashAnim.mixer.update(elapsedTime / 1000);
            }

            var confettiTime = 2.8;
            var progressTime = Math.max(0, this.earthCrashAnim.mixer.time % this.earthCrashAnim.duration - confettiTime);
            this.confettiMesh.visible = progressTime > 0 ? true : false;
            this.confettiMat.uniforms.progress.value = progressTime / (this.earthCrashAnim.duration - confettiTime);
            this.dirLight.shadow.needsUpdate = true;
          };
        }
      });
      gsap.to(this.sceneInner.scale, {
        duration: _transitionDuration,
        ease: _transitionEase,
        x: earthCrashScale,
        y: earthCrashScale,
        z: earthCrashScale
      });
      gsap.to(this.moonMainGroup.position, {
        duration: _transitionDuration,
        ease: _transitionEase,
        y: 3.64442
      });
      gsap.to(this.dirLight.position, {
        duration: _transitionDuration,
        ease: _transitionEase,
        x: -50,
        y: 50,
        z: 50
      }); // gsap.to(this.moonMainGroup.rotation, {duration:_transitionDuration, ease:_transitionEase, z:Math.PI/2});
    });
  }

  hideEarthCrash() {
    return new Promise(resolve => {
      this.resetStateFunctions();
      this.dirLight.position.copy(dirLightPosition);
      this.moonMainGroup.position.set(0, 0, 0);
      this.sceneInner.scale.set(1, 1, 1);
      this.sceneInner.add(this.moonMainGroup);
      this.sceneInner.remove(this.earthCrashGltf.scene);
      resolve();
    });
  } // ----------- SHOW FLY TO SHUTTLE -----------


  showFlyToShuttle() {
    return new Promise(resolve => {
      moonParticles.show();
      var startRot = this.moonMainGroup.quaternion.clone();
      var destRot = this.sceneAdjustedRotation(new Euler(-moonHotspots[3].lat * (Math.PI / 180), Math.PI / 2 + -moonHotspots[3].long * (Math.PI / 180), 0, "XYZ"), false);
      var sceneQuat = this.moonMainGroup.quaternion;
      gsap.to({
        prog: 0
      }, {
        duration: transitionDuration,
        ease: transitionEase,
        prog: 1,
        onUpdate: function onUpdate() {
          sceneQuat.slerpQuaternions(startRot, destRot, this._targets[0].prog);
        },
        onComplete: () => {
          resolve();
        }
      }); // if I am going to move the moon group so that the ship is centered in the scene I will 
      // need to know where the ship is going to be AFTER the transforms are all completed
      // once i know that I can figure out how far to move the ship.
      // lets try to create a transform.
      // we need a position Matrix

      var newTransform = new Matrix4().makeRotationFromQuaternion(destRot);
      newTransform.multiplyMatrices(this.sceneInner.matrixWorld, newTransform);
      var newWorldPos = this.shuttleMesh.position.clone().applyMatrix4(newTransform);
      var offsetPos = this.sceneInner.worldToLocal(newWorldPos);
      console.log('now we animate moonMainGroup position to center the shuttle');
      console.log(this.shuttleMesh.position.x, this.shuttleMesh.position.y, this.shuttleMesh.position.z); // this.sceneInner.quaternion.copy(destRot);

      gsap.to(this.moonMainGroup.position, {
        duration: transitionDuration,
        ease: transitionEase,
        x: -offsetPos.x * flyToShuttleScale,
        y: -offsetPos.y * flyToShuttleScale,
        z: -offsetPos.z * flyToShuttleScale
      });
      gsap.to(this.moonMainGroup.scale, {
        duration: transitionDuration,
        ease: transitionEase,
        x: flyToShuttleScale,
        y: flyToShuttleScale,
        z: flyToShuttleScale
      });
    });
  }

  hideFlyToShuttle() {
    return new Promise(resolve => {
      this.resetStateFunctions();
      this.sceneInner.remove(this.wreckageGroup);
      resolve();
    });
  }

}

export default {
  getInstance() {
    instance = instance || new ARScene();
    return instance;
  }

};