import { Howl, Howler } from 'howler';
import siteFX from '../../../assets/audio/siteFX';
var initialized = false;
var siteIndex = 0;
var currentSound = null;
var muted = false;
var paused = false;
var siteFXPool = [];
var sounds = {};
var numObjs = 5; // Howler global settings

Howler.mobileAutoEnable = true;
var sfx = {
  init() {},

  loadFX(section) {
    if (initialized) return;
    initialized = true;

    switch (section) {
      case 'site':
        // iterate through sprites and add true to any that have _Loop in the title, so they loop when played
        Object.keys(siteFX.sprite).forEach(key => {
          if (key.match(/_Loop/)) {
            siteFX.sprite[key].push(true);
          }
        });
        siteFXPool[1] = new Howl(siteFX);
        siteFXPool[1].preload = true;
        break;

      default:
        console.log('Wrong section, no FX!');
    }
  },

  getDuration(id) {
    return new Promise(resolve => {
      if (!sounds[id]) {
        this.loadSound(id).then(() => this.getDuration(id)).then(duration => {
          resolve(duration);
        });
      } else {
        resolve(sounds[id].duration());
      }
    });
  },

  loadSound(id) {
    var loop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return new Promise(resolve => {
      var urls = ["/assets/audio/".concat(id, ".ogg"), "/assets/audio/".concat(id, ".m4a"), "/assets/audio/".concat(id, ".mp3"), "/assets/audio/".concat(id, ".ac3")];
      sounds[id] = new Howl({
        src: urls,
        preload: true,
        loop,
        onload: () => {
          console.log('ON LOAD?');
          resolve();
        }
      });
      sounds[id].id = id;
    });
  },

  getSound(id) {
    return sounds[id];
  },

  playFx(id) {
    var loop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var section = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'site';
    if (!initialized) return;
    var fxPool;
    var fxIndex;
    var fxInfo;
    var volume = 1;

    switch (section) {
      case 'site':
        fxPool = siteFXPool;
        fxIndex = (siteIndex + 1) % numObjs;
        fxInfo = siteFX;
        volume = 1;
        break;

      default:
        console.log('Wrong section, no FX!');
    }

    if (!fxPool[fxIndex]) {
      fxInfo.loop = loop;
      fxPool[fxIndex] = new Howl(fxInfo);
    }

    fxPool[fxIndex].volume(volume);
    return fxPool[fxIndex].play(id);
  },

  stopFx(id) {
    if (!initialized) return;
    var fxPool = siteFXPool;
    var fxIndex = (siteIndex + 1) % numObjs;
    fxPool[fxIndex].stop(id); // Howler.stop(id);
  },

  fadeInSound(id) {
    var volume = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    console.log(this);

    if (!sounds[id]) {
      if (currentSound) currentSound.stop();
      this.loadSound(id);
    }

    currentSound = sounds[id];
    sounds[id].fade(0, volume, 500);
    currentSound.play();
    return currentSound;
  },

  playSound(id) {
    var seek = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

    if (!sounds[id]) {
      if (currentSound) currentSound.stop();
      this.loadSound(id);
    }

    currentSound = sounds[id];
    console.log(seek);
    currentSound.seek(seek);
    currentSound.play();
    return currentSound;
  },

  stopSound(id) {
    if (sounds[id]) {
      sounds[id].stop();
    } else if (currentSound) {
      currentSound.stop();
    }
  },

  mute(bool) {
    muted = bool;
    Howler.mute(muted || paused);
  },

  setVolume(id, volume) {
    var section = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'site';
    var fxPool;
    var fxIndex;
    var fxInfo;

    switch (section) {
      case 'site':
        fxPool = siteFXPool;
        fxIndex = siteIndex % numObjs;
        fxInfo = siteFX;
        break;

      default:
        console.log('Wrong section, no FX!');
    }

    if (!fxPool[fxIndex]) {
      fxPool[fxIndex] = new Howl(fxInfo);
    }

    fxPool[fxIndex].volume(volume, id);
  },

  pause(bool) {
    paused = bool;
    this.mute(muted);
  }

};
window.sfx = sfx;
export default sfx;