function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { gsap } from 'gsap/all';
import { BufferGeometry, DataTexture, FloatType, BufferAttribute, Color, HalfFloatType, RepeatWrapping, Vector3, DoubleSide, ShaderMaterial, NearestFilter, RGBAFormat } from 'three';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min';
import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer';
import positionFragmentShader from './shaders/position_frag.glsl';
import velocityFragmentShader from './shaders/velocity_frag.glsl';
import fragmentShader from './shaders/frag.glsl';
import vertexShader from './shaders/particles_vert.glsl';
import { Points } from 'three';
import { TextureLoader } from 'three';
import app from '../../global';
var instance;
var scene;
var renderer;
var camera;
var target_position = new Vector3();
/* TEXTURE WIDTH FOR SIMULATION */

var WIDTH = 64;
var HEIGHT = 64;
var POINTS = WIDTH * HEIGHT;
var mouseX = 0;
var mouseY = 0;
var starttime = Date.now(); // test stuff
// const testGeo = new SphereBufferGeometry(0.5, 32, 32);
// const testMat = new MeshBasicMaterial({ color: 0x00FFFF });
// const testMesh = new Mesh(testGeo, testMat);

function isSafari() {
  return !!navigator.userAgent.match(/Safari/i) && !navigator.userAgent.match(/Chrome/i);
}

function getPoint(v, size) {
  var x = 0;
  var y = 0;
  var z = 0;

  do {
    x = Math.random() * (size * 2) - size;
    y = Math.random() * (size * 2) - size;
    z = Math.random() * (size * 2) - size;
  } while (Math.sqrt(x * x + y * y + z * z) >= size);

  v.set(x, y, z);
  return v;
} // returns a Float32Array buffer of spherical 3D points


function getSphere(count, size) {
  console.log('COUNT', count);
  var len = count * 3;
  var data = new Float32Array(len);
  var p = new Vector3();

  for (var i = 0; i < len; i += 3) {
    getPoint(p, size);
    data[i] = p.x;
    data[i + 1] = p.y;
    data[i + 2] = p.z;
  }

  return data;
}

function getSpawnData() {
  var size = WIDTH * HEIGHT;
  var stride = 4;
  var data = new Float32Array(stride * size);
  var vec = new Vector3();
  var vertices = this.geo.attributes.position.array;

  for (var i = 0; i < size; i++) {
    var x = vertices[i * 3];
    var y = vertices[i * 3 + 1];
    var z = vertices[i * 3 + 2];
    vec.set(x, y, z);
    data[i * stride + 0] = vec.x;
    data[i * stride + 1] = vec.y;
    data[i * stride + 2] = vec.z;
    data[i * stride + 3] = 250 + 750 * Math.random(); // BASE_LIFETIME = 10, MAX_ADDITIONAL_LIFETIME = 5
  }

  var texture = new DataTexture(data, WIDTH, HEIGHT, RGBAFormat);
  texture.wrapS = RepeatWrapping;
  texture.wrapT = RepeatWrapping; // texture.format = RGBFormat;

  texture.type = FloatType;
  texture.minFilter = NearestFilter;
  texture.magFilter = NearestFilter;
  texture.needsUpdate = true;
  return texture;
}

function fillPositionTexture(texture) {
  var theArray = texture.image.data;
  var vec = new Vector3();
  var vertices = this.geo.attributes.position.array;

  for (var i = 0; i < POINTS; i++) {
    var x = vertices[i * 3];
    var y = vertices[i * 3 + 1];
    var z = vertices[i * 3 + 2];
    vec.set(x, y, z);
    theArray[i * 4 + 0] = vec.x;
    theArray[i * 4 + 1] = vec.y;
    theArray[i * 4 + 2] = vec.z;
    theArray[i * 4 + 3] = 250 + 750 * Math.random();
  }
}

function fillVelocityTexture(texture) {
  var theArray = texture.image.data;

  for (var k = 0, kl = theArray.length; k < kl; k += 4) {
    var vec = new Vector3();
    vec.set(Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1); // vec.set(0, 0, 0);
    // const x = Math.random() - 0.5;
    // const y = Math.random() - 0.5;
    // const z = Math.random() - 0.5;

    theArray[k + 0] = vec.x;
    theArray[k + 1] = vec.y;
    theArray[k + 2] = vec.z;
    theArray[k + 3] = Math.random();
  }
}

function initComputeRenderer() {
  this.gpuCompute = new GPUComputationRenderer(WIDTH, HEIGHT, renderer);

  if (isSafari()) {
    this.gpuCompute.setDataType(HalfFloatType);
  } // const spawnData = getSpawnData();//this.gpuCompute.createTexture();
  // // fillPositionTexture.call(this, spawnData);


  var dtPosition = this.gpuCompute.createTexture();
  var dtVelocity = this.gpuCompute.createTexture();
  fillPositionTexture.call(this, dtPosition);
  fillPositionTexture.call(this, dtVelocity);
  this.velocityVariable = this.gpuCompute.addVariable('textureVelocity', velocityFragmentShader, dtVelocity);
  this.positionVariable = this.gpuCompute.addVariable('texturePosition', positionFragmentShader, dtPosition);
  this.gpuCompute.setVariableDependencies(this.velocityVariable, [this.velocityVariable, this.positionVariable]);
  this.gpuCompute.setVariableDependencies(this.positionVariable, [this.positionVariable, this.velocityVariable]);
  var positionUniforms = this.positionVariable.material.uniforms;
  var velocityUniforms = this.velocityVariable.material.uniforms;
  var spawnData = getSpawnData.call(this);
  var target = new Vector3();
  positionUniforms.time = {
    value: 0.0
  };
  positionUniforms.delta = {
    value: 0.0
  };
  positionUniforms.scale = {
    value: 1.0
  };
  positionUniforms.target = {
    value: target
  };
  positionUniforms.u_spawnTexture = {
    value: spawnData
  };
  velocityUniforms.time = {
    value: 0.0
  };
  velocityUniforms.delta = {
    value: 0.0
  };
  velocityUniforms.target = {
    value: target
  };
  velocityUniforms.scale = {
    value: 1.0
  };
  velocityUniforms.u_spawnTexture = {
    value: spawnData
  }; // velocityUniforms.noiseTex = { value: noiseTex };
  // velocityUniforms.multiTime = { value: new Vector2(Math.random() * 2 - 1, Math.random() * 2 - 1) };
  // velocityUniforms.randoms = { value: getRandomsTexture() };
  // velocityUniforms.u_spawnTexture = { value: getSpawnData.call(this) };

  this.velocityVariable.wrapS = RepeatWrapping;
  this.velocityVariable.wrapT = RepeatWrapping;
  this.positionVariable.wrapS = RepeatWrapping;
  this.positionVariable.wrapT = RepeatWrapping;
  var error = this.gpuCompute.init();

  if (error !== null) {
    console.error(error);
  }
}

class MoonParticles {
  constructor() {
    _defineProperty(this, "texLoader", new TextureLoader());

    _defineProperty(this, "dirLight", new Vector3(-150, 100, -10));

    var vertices = getSphere(POINTS, 0.05);
    this.geo = new BufferGeometry();
    this.geo.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));
    var reference = [];
    var seeds = [];

    for (var i = 0; i < POINTS; i++) {
      // const bIndex = i % ( birdGeo.getAttribute( 'position' ).count );
      var point = i; // if ( bIndex == 0 ) r = Math.random();

      var j = ~~point;
      var x = j % WIDTH / WIDTH;
      var y = ~~(j / WIDTH) / WIDTH;
      reference.push(x, y); // console.log(reference);

      seeds.push(point, Math.random(), Math.random(), Math.random());
    }

    this.geo.setAttribute('reference', new BufferAttribute(new Float32Array(reference), 2));
    this.geo.setAttribute('seeds', new BufferAttribute(new Float32Array(seeds), 4));
  }

  start(_scene, _renderer, _camera, callback) {
    camera = _camera; // this.camera.position.z = 10;
    // camera.position.z = windowHalfY / Math.tan(20 * Math.PI / 180);

    renderer = _renderer;
    scene = _scene; // this.scene.background = new Color(0x00FFFF);

    var effectController = {
      count: POINTS,
      size: 12.
    };
    var gui;

    if (app.debug) {
      gui = new GUI();
    }

    var valuesChanger = () => {
      this.geo.setDrawRange(0, effectController.count);
    };

    valuesChanger();

    if (app.debug) {
      gui.add(effectController, 'count', 0, POINTS, 1).onChange(valuesChanger);
      gui.close();
    }

    var m = new ShaderMaterial({
      uniforms: {
        texturePosition: {
          value: null
        },
        textureVelocity: {
          value: null
        },
        time: {
          value: 1.0
        },
        size: {
          value: effectController.size
        },
        scale: {
          value: 1.0
        },
        delta: {
          value: 0.0
        },
        dirlight: {
          value: new Vector3()
        },
        highlight_color: {
          value: new Color(0xFFFFFF)
        },
        shadow_color: {
          value: new Color(0x010101)
        }
      },
      side: DoubleSide,
      transparent: true,
      vertexShader,
      fragmentShader,
      depthTest: true
    });
    this.mesh = window.themesh = new Points(this.geo, m);
    this.mesh.frustumCulled = false;
    scene.add(this.mesh); // initPoints(effectController);

    this.resize(); // const noiseTex = response;
    // const noiseTex2 = response[0];
    // noiseTex.wrapS = RepeatWrapping;
    // noiseTex.wrapT = RepeatWrapping;
    // noiseTex.format = RGBFormat;
    // noiseTex.type = FloatType;
    // noiseTex.minFilter = NearestFilter;
    // noiseTex.magFilter = NearestFilter;

    initComputeRenderer.call(this);
    callback();
  }

  setPosition(position, duration, callback) {
    // console.log('MOON PARTICLES POS', position);
    if (duration) {
      gsap.to(target_position, {
        duration,
        x: position.x,
        y: position.y,
        z: position.z,
        ease: 'power2.in'
      });
    } else {
      target_position.copy(position);
    } // if (this.testmesh) this.testmesh.position.copy(position);

  }

  setParticleSize(size) {
    this.mesh.material.uniforms.size.value = size;
  }

  setParticleScale(scale) {
    this.positionVariable.material.uniforms.scale.value = scale;
    this.velocityVariable.material.uniforms.scale.value = scale;
  }

  update(_ref) {
    var {
      delta
    } = _ref;
    if (this.paused || !this.positionVariable) return;
    var now = Date.now() - starttime;
    var positionUniforms = this.positionVariable.material.uniforms;
    var velocityUniforms = this.velocityVariable.material.uniforms;
    positionUniforms.time.value = now;
    positionUniforms.delta.value = delta;
    positionUniforms.target.value.copy(target_position); // positionUniforms.scale.value = this.mesh.scale.x;
    // console.log(target_position);

    velocityUniforms.time.value = now;
    velocityUniforms.delta.value = delta;
    velocityUniforms.target.value.copy(target_position); // const t = now;
    // console.log((now / 1000) % 2);

    this.gpuCompute.compute();
    var {
      uniforms
    } = this.mesh.material;
    uniforms.time.value = now;
    uniforms.delta.value = delta;
    uniforms.scale.value = positionUniforms.scale.value;
    uniforms.dirlight.value.copy(this.dirLight);
    uniforms.texturePosition.value = this.gpuCompute.getCurrentRenderTarget(this.positionVariable).texture;
    uniforms.textureVelocity.value = this.gpuCompute.getCurrentRenderTarget(this.velocityVariable).texture; // renderer.render(scene, camera);
  }

  show() {
    if (this.mesh) this.mesh.visible = true;
  }

  hide() {
    if (this.mesh) this.mesh.visible = false;
  }

  play() {
    this.paused = false;
  }

  pause() {
    this.paused = true;
  }

  resize(w, h) {
    this.winHalfX = w * 0.5;
    this.winHalfY = h * 0.5; // camera.aspect = w / h;
    // camera.updateProjectionMatrix();
  }

}

export default {
  getInstance() {
    instance = instance || new MoonParticles();
    return instance;
  }

};