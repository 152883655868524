import LazyLoad from 'vanilla-lazyload'; //

import ArrayExecutor from './app/utils/ArrayExecutor';
import Navigation from './app/utils/Navigation';
import AR from './app/sections/AR';
import Desktop from './app/sections/Desktop';
import Preloader from './app/utils/Preloader';
import SectionLoader from './app/utils/SectionLoader';
import ARPipeline from './app/widgets/ARPipeline';
import sfx from './app/utils/sfx';
import app from './app/global';
var arpipeline = app.arpipeline = ARPipeline.getInstance();
var sections = {
  AR,
  Desktop
};
app.verbose = true;
app.debug = window.location.hash.includes('#debug');
app.debug_shortcut = window.location.hash.includes('#shortcut');
app.noar = window.location.hash.includes('#noar');
app.site_path = window.site_path;
app.site_url = window.location.origin;
var arrayExecutor = ArrayExecutor(null, 'Main');
var navigation = Navigation.getInstance();
var sectionLoader = SectionLoader.getInstance();
var preloader = Preloader.getInstance();
preloader.setLoader('LoaderUI');

function handleResize() {
  if (app.mainMenu) app.mainMenu.resize();
  app.sections[navigation.currentSection].resize();
}

function initSoundButton() {
  // Set the name of the hidden property and the change event for visibility
  var visibilityChange;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support 
    visibilityChange = 'visibilitychange';
  } else if (typeof document.mozHidden !== 'undefined') {
    visibilityChange = 'mozvisibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    visibilityChange = 'webkitvisibilitychange';
  }
  /**
   * Mute audio when user opens a different browser tab, unmute when returning 
   * to site if audio was not previously muted by clicking the sound button
   */


  document.addEventListener(visibilityChange, e => {
    if (e.target.hidden === true) {
      sfx.mute(true);
      arpipeline.paused = true;
    } else if (e.target.hidden === false) {
      if (sound_button.classList.contains('on')) sfx.mute(false);
      arpipeline.paused = false;
    } else {
      console.log('unknown page visibility status');
    }
  });
  var sound_button = document.getElementById('soundButton');
  sound_button.classList.remove('off');
  sound_button.classList.add('on');
  sound_button.addEventListener('click', () => {
    var toggleMute = true;

    if (sound_button.classList.contains('on')) {
      sound_button.classList.remove('on');
      sound_button.classList.add('off');
      if (toggleMute) sfx.mute(true);
    } else {
      sound_button.classList.remove('off');
      sound_button.classList.add('on');
      if (toggleMute) sfx.mute(false);
    }
  });
}

function siteIsIn() {
  console.log('SITE IS IN');
  navigation.changeOrder = ['section_hide_prev', 'section_remove_prev', 'section_shutdown_prev', 'load', 'section_add_next', 'section_init_next', 'section_startup_next', 'section_show_next'];
  document.body.classList.add('sitein');
  if (app.mainMenu) app.mainMenu.show();
  if (app.footer) app.footer.show();
  handleResize();
  initSoundButton();
}

function setupSectionObjects(callback) {
  app.sections = {};
  Object.keys(sections).reduce((o, section) => {
    var obj = o;
    obj[section.toLowerCase()] = sections[section].getInstance();
    sectionLoader.addSection(section, {
      files: []
    });
    return obj;
  }, app.sections);
  if (callback) callback();
}

function setupMenu(callback) {
  console.log('SET UP MENU');

  if (typeof Menu !== 'undefined') {
    app.mainMenu = Menu.getNew();
    app.mainMenu.init();
  }

  callback();
}

function setupFooter(callback) {
  console.log('SET UP FOOTER');

  if (typeof Footer !== 'undefined') {
    app.footer = Footer.getInstance();
    app.footer.init();
  }

  callback();
}

function init() {
  setupSectionObjects();
  navigation.currentSection = document.body.dataset.section;

  if (!XR8.XrDevice.isDeviceBrowserCompatible({
    allowedDevices: XR8.XrConfig.device().MOBILE
  })) {
    if (window.location.href.match(/\/desktop\//)) {
      app.settings.homeSection = 'desktop';
      navigation.currentSection = 'Desktop';
    } else {
      window.location.href = '/desktop/';
    }
  } else if (window.location.href.match(/\/desktop\//)) {
    window.location.href = '/';
  } // alert(navigation.currentSection);


  navigation.forceChange = true;
  navigation.changeOrder = ['load', 'section_add_next', 'section_init_next', 'section_startup_next', 'section_show_next'];
  navigation.loadQueue('main');
  var functionArr = [];
  Array.prototype.push.apply(functionArr, [// { fn: navigation.load, scope: navigation, vars: null },
  {
    fn: setupMenu,
    vars: null
  }, {
    fn: setupFooter,
    vars: null
  }, {
    fn: navigation.changeSection,
    scope: navigation,
    vars: [window.location.pathname]
  }, {
    fn: siteIsIn
  }]);
  arrayExecutor.execute(functionArr);
}

function Main() {
  app.settings = app.settings || {};
  app.sections = {};
  app.settings.homeSection = 'ar';
  sfx.loadFX('site');
  app.background_loop = sfx.playFx('MF_MX_Drone_Loop_01');
  document.documentElement.classList.add('no-touch');
  window.addEventListener('touchstart', function setHasTouch() {
    document.documentElement.classList.remove('no-touch');
    document.documentElement.classList.add('touch');
    if (app.mainMenu && app.mainMenu.setTouch) app.mainMenu.setTouch();
    window.removeEventListener('touchstart', setHasTouch);
  }, false);
  window.addEventListener('resize', handleResize);
  window.addEventListener('onorientationchange', handleResize);
  arpipeline.events.subscribe('loaded', () => {
    console.log('LOADED');
    var load_wrapper = document.querySelector('#loadImageContainer');
    var loader = document.createElement('div');
    loader.classList.add('loader');
    loader.innerHTML = '<div></div><div></div>';
    load_wrapper.appendChild(loader);
  }); // arpipeline.events.subscribe('onStart', () => {
  //   window.setTimeout(() => {
  //     // states.landing.show();
  //     // document.querySelector('.rings').classList.add('show');
  //     console.log('ON START');
  //   }, 1000);
  // });

  arpipeline.init(() => {
    console.log('INIT AR PIPELINE');
    console.log('INIT!!');
    init();
  });
}

Main.prototype.resize = handleResize;
app.main = new Main();
window.addEventListener('beforeunload', () => {
  window.scroll(0, 0); // alert('BEFORE UNLOAD');
});