/* eslint-disable no-mixed-operators */

/* eslint-disable prefer-destructuring */

/* eslint-disable class-methods-use-this */
import * as dat from 'dat.gui';
import { Vector3 } from 'three';
import { gsap, mapRange, clamp } from 'gsap/all';
import ARPipeline from '../widgets/ARPipeline';
import ARScene from '../widgets/ARScene';
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import pubsub from '../utils/pubsub';
import app from '../global';
import MoonParticles from '../widgets/Particles/MoonParticles';
import sfx from '../utils/sfx'; // import findDarkest from '../widgets/findDarkPixels';

var myName = 'AR';
var sectionLoader = SectionLoader.getInstance();
var moonParticles = MoonParticles.getInstance();
var particlesPositionCamSpace = new Vector3();
var particlesPosition = new Vector3();
var arpipeline = app.arpipeline = ARPipeline.getInstance();
var arscene;
var showparticles = true;
var updateFn = null;
var angle = 0;
var calibration_loop;
var background_loop = app.background_loop;
var states = {
  landing: {
    show: function show() {
      var landing_wrapper = document.querySelector('#ar_landing');
      var rect = landing_wrapper.querySelector('.rings').getBoundingClientRect(); // landing_wrapper.classList.add('show');

      particlesPositionCamSpace.set(0, -1 * ((rect.y + rect.height * 0.5) / window.innerHeight * 2 - 1), 0.99);

      if (showparticles) {
        arscene.camera.updateMatrixWorld();
        var {
          scene,
          renderer,
          camera
        } = arscene;
        moonParticles.start(scene, renderer, camera, () => {
          moonParticles.hide();
          updateFn = states.landing.update; // window.setTimeout(() => {
          //   landing_wrapper.querySelector('.rings').classList.add('show');
          // }, 500);
        });
      } else {
        window.setTimeout(() => {
          landing_wrapper.querySelector('.rings').classList.add('show');
        }, 500);
      }

      if (arscene) {
        arscene.positionMoonLanding(particlesPositionCamSpace.x, particlesPositionCamSpace.y, 0.99);
        arscene.showMoonLanding().then(() => {
          console.log('SHOW MOON LANDING DONE');
          if (!showparticles) return;
          window.setTimeout(() => {
            landing_wrapper.classList.add('show');
            landing_wrapper.querySelector('.rings').classList.add('show');
          }, 1500);
          var moonmesh = arscene.moonLandingMesh;
          var m = moonmesh.matrixWorld;
          var s = new Vector3().setFromMatrixScale(m).x; // moonParticles.setParticleScale(s * 30);
        });
      }

      landing_wrapper.classList.remove('hide');
    },
    hide: function hide() {
      sfx.playFx('MF_SX_Tap_To_Start_01');
      document.querySelector('#ar_landing .rings').classList.remove('show');
      document.querySelector('#ar_landing').classList.add('hide');
      if (showparticles) moonParticles.show();
      gsap.to(particlesPositionCamSpace, {
        z: 0.96,
        duration: 2.5,
        ease: 'power3.inOut'
      });
      arscene.hideMoonLanding().then(() => {
        console.log('HIDE MOON LANDING DONE'); // moonParticles.hide();

        states.finding_moon.show();
      }); // window.setTimeout(() => {
      //   document.querySelector('#ar > .rings').classList.add('show');
      // }, 1000);
    },
    update: function update() {
      arscene.camera.updateMatrixWorld();
      particlesPosition.copy(particlesPositionCamSpace).unproject(arscene.camera);
      moonParticles.mesh.position.copy(particlesPosition); // moonParticles.setPosition(0, 0, particlesPosition.z);
      // if (showparticles) moonParticles.setPosition(particlesPosition.copy(particlesPositionCamSpace).unproject(arscene.camera));
    }
  },
  finding_moon: {
    showArrow: function showArrow(onscreen) {
      if (!states.finding_moon.ready) return;
      if (Date.now() < states.finding_moon.delay) return;
      states.finding_moon.onscreen = onscreen;

      if (onscreen) {
        states.finding_moon.getArrow().classList.remove('show');
        states.finding_moon.getArrow().classList.add('hide');
      } else {
        states.finding_moon.getArrow().classList.remove('hide');
        states.finding_moon.getArrow().classList.add('show');
      }
    },
    getArrow: function getArrow() {
      states.finding_moon.arrow = states.finding_moon.arrow || document.querySelector('.arrow_ring');
      return states.finding_moon.arrow;
    },
    getElement: function getElement() {
      states.finding_moon.element = states.finding_moon.element || document.querySelector('#moon_target');
      return states.finding_moon.element;
    },
    show: function show() {
      states.finding_moon.time = 0;
      states.finding_moon.last = 0;
      updateFn = states.landing.update;
      document.querySelector('#ar_find_moon').classList.remove('hide');
      window.setTimeout(() => {
        states.finding_moon.delay = Date.now() + 2000;
        window.setTimeout(() => {
          states.finding_moon.ready = true;

          if (arscene) {
            arscene.showFindMoon().then(() => {
              window.setTimeout(() => {
                document.querySelector('#ar_find_moon').classList.add('show');
                document.querySelector('#ar > .rings').classList.add('show');
              }, 1000);
            });
            moonParticles.setPosition(arscene.findMoonMesh.position, 4);
          }
        }, 500);
      }, 500);
    },
    hide: function hide() {
      document.querySelector('#ar_find_moon').classList.add('hide');
      states.finding_moon.ready = false;
      updateFn = null;
    },
    moonLocked: function moonLocked() {
      document.querySelector('#ar_find_moon').classList.add('locked');
    }
  },
  calibrating_moon: {
    getProgressElement: function getProgressElement() {
      states.calibrating_moon.element = states.calibrating_moon.element || document.querySelector('#ar_calibrating .calibration_progress div');
      return states.calibrating_moon.element;
    },
    show: function show() {
      updateFn = null;
      document.querySelector('#ar_calibrating').classList.remove('hide');
      document.querySelector('#ar_calibrating').classList.add('show');
    },
    hide: function hide() {
      sfx.stopFx(calibration_loop);
      sfx.playFx('MF_SX_Moon_Calibration_End_01', true);
      document.querySelector('#ar_calibrating').classList.add('hide');
    }
  },
  placing_moon: {
    show: function show() {
      updateFn = null;
      document.querySelector('#ar_place_moon').classList.remove('hide');
      document.querySelector('#ar_place_moon').classList.add('show');
    },
    hide: function hide() {
      document.querySelector('#ar_place_moon').classList.add('hide');
    }
  },
  zoomed: {
    particleSettings: {
      angle: 0,
      particleScale: new Vector3(),
      shuttlePosition: new Vector3(),
      axis: new Vector3(0, 0, 1),
      radius: 0,
      scale: 1
    },
    attackShuttle: function attackShuttle() {
      var moonmesh = arscene.moonMesh;
      if (states.zoomed.particleSettings.tl) states.zoomed.particleSettings.tl.kill();

      if (moonmesh && showparticles) {
        var settings = states.zoomed.particleSettings;
        var vector = new Vector3(0, 0, 1);
        var tl = states.zoomed.particleSettings.tl = gsap.timeline({
          paused: true,
          onUpdate: () => {
            vector.set(0, 0, 1).applyAxisAngle(settings.axis, settings.angle).normalize().multiplyScalar(states.zoomed.particleSettings.radius * states.zoomed.particleSettings.scale);
            moonParticles.setPosition(vector);
          },
          onComplete: () => {
            states.zoomed.attackShuttle();
          }
        });
        tl.to(states.zoomed.particleSettings, {
          angle: Math.PI + Math.random() * Math.PI,
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.to(states.zoomed.particleSettings.axis, {
          x: Math.random() * 2 - 1,
          y: Math.random() * 2 - 1,
          z: Math.random() * 2 - 1,
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.to(states.zoomed.particleSettings, {
          radius: 0.05 + Math.random() * 0.05,
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.play();
      }
    },
    show: function show() {
      sfx.playFx('MF_SX_Place_Moon_01');
      updateFn = states.zoomed.update; // arscene.shuttleMesh.add(moonParticles.mesh);
      // moonParticles.setPosition(arscene.shuttleMesh.position);

      document.querySelector('#ar_zoomed').classList.remove('hide');
      document.querySelector('#ar_zoomed').classList.add('show');
      moonParticles.mesh.position.set(0, 0, 0);
      moonParticles.setPosition(new Vector3());
      states.zoomed.attackShuttle();
    },
    hide: function hide() {
      document.querySelector('#ar_zoomed').classList.add('hide');
    },
    update: function update() {
      var settings = states.zoomed.particleSettings;
      var m = arscene.moonMesh.matrixWorld;
      var s = settings.particleScale.setFromMatrixScale(m).x;
      moonParticles.setParticleScale(0.25 * s); // console.log(s);

      settings.scale = s;
      arscene.shuttleMesh.getWorldPosition(settings.shuttlePosition);
      moonParticles.mesh.position.copy(settings.shuttlePosition);
    }
  },
  hotspots: {
    mapper1: mapRange(0, 1, 0, 1),
    mapper2: mapRange(0, 1, 0.25, 1),
    dossier: {
      initialized: false,
      init: function init() {
        states.hotspots.dossier.initialized = true;
        var yt_modal = document.querySelector('#youtube_modal');
        yt_modal.querySelector('iframe').setAttribute('src', '');
        document.body.querySelector('#dossier .files').addEventListener('click', e => {
          console.log('CLICK THE LIST!', e.target);
          var ytid = e.target.dataset.ytid;

          if (ytid) {
            yt_modal.querySelector('iframe').setAttribute('src', "https://www.youtube.com/embed/".concat(ytid));
            sfx.playFx('MF_SX_Window_Generic_Wipe_Open_v1');
            yt_modal.classList.add('show');
            sfx.stopFx(background_loop);
            e.preventDefault();
          }
        });
        yt_modal.addEventListener('click', e => states.hotspots.dossier.hideYTModal());
      },
      hideYTModal: function hideYTModal() {
        var yt_modal = document.querySelector('#youtube_modal');
        sfx.playFx('MF_SX_Window_Generic_Wipe_Close_v1');
        app.background_loop = sfx.playFx('MF_MX_Drone_Loop_01');
        yt_modal.classList.remove('show');
        window.setTimeout(() => {
          yt_modal.querySelector('iframe').setAttribute('src', '');
        }, 500);
      },
      getElement: function getElement() {
        states.hotspots.dossier.element = states.hotspots.dossier.element || document.querySelector('[data-content_id=dossier]');
        return states.hotspots.dossier.element;
      },
      show: function show() {
        return new Promise(resolve => {
          if (states.hotspots.dossier.initialized === false) states.hotspots.dossier.init();
          updateFn = null;
          document.querySelector('#dossier').classList.remove('hide');
          document.querySelector('#dossier').classList.add('show');
          sfx.playFx('MF_SX_Classified_Open_01');
          resolve();
        });
      },
      hide: function hide() {
        var yt_modal = document.querySelector('#youtube_modal');

        if (yt_modal.classList.contains('show')) {
          states.hotspots.dossier.hideYTModal();
          return true;
        }

        sfx.playFx('MF_SX_Classified_Closed_01');
        document.querySelector('#dossier').classList.add('hide');
        states.zoomed.show();
      }
    },
    bagel: {
      getElement: function getElement() {
        states.hotspots.bagel.element = states.hotspots.bagel.element || document.querySelector('[data-content_id=bagel]');
        return states.hotspots.bagel.element;
      },
      show: function show() {
        return new Promise(resolve => {
          updateFn = null;
          document.querySelector('#bagel').classList.remove('hide', 'show');

          if (arscene) {
            arscene.hideMoonMain().then(() => {
              sfx.playFx('MF_SX_Bagel_Moon_FullSeq_01');
              arscene.showEarthCrash().then(() => {
                sfx.playFx('MF_SX_Window_Generic_Wipe_Open_v1');
                document.querySelector('#bagel').classList.add('show'); // there's some weird sizing stuff with the gleamio iframe embed, and turning it off then on again fixes it

                document.querySelector('#bagel iframe').style.display = 'none';
                window.requestAnimationFrame(() => {
                  document.querySelector('#bagel iframe').style.display = 'block';
                });
                resolve();
              });
            });
          } else {
            document.querySelector('#bagel').classList.add('show');
            window.setTimeout(() => {
              document.querySelector('#bagel iframe').style.display = 'none';
              window.setTimeout(() => {
                document.querySelector('#bagel iframe').style.display = 'block';
              }, 200);
            }, 200);
          }
        });
      },
      hide: function hide() {
        sfx.playFx('MF_SX_Window_Generic_Wipe_Close_v1');
        document.querySelector('#bagel').classList.add('hide');

        if (arscene) {
          arscene.hideEarthCrash().then(() => {
            console.log('bagel IS HIDDEN');
            arscene.showMoonMain().then(() => {
              console.log('MOON MAIN SHOWN');
              states.zoomed.show();
            });
          }); // arscene.hideWreckage().then(() => {
          //   console.log('bagel IS HIDDEN');
          //   arscene.showMoonMain().then(() => {
          //     console.log('MOON MAIN SHOWN');
          //     states.zoomed.show();
          //   });
          // });
          // arscene.showMoonMain().then(() => {
          //   console.log('MOON MAIN SHOWN');
          //   states.zoomed.show();
          // });
        }
      }
    },
    cloud: {
      getElement: function getElement() {
        states.hotspots.cloud.element = states.hotspots.cloud.element || document.querySelector('[data-content_id=cloud]');
        return states.hotspots.cloud.element;
      },
      show: function show() {
        return new Promise(resolve => {
          setTimeout(() => {
            sfx.playFx('MF_SX_Projectile_01');
          }, 4100);
          updateFn = null;
          if (showparticles) moonParticles.mesh.position.set(0, 0, 0);
          document.querySelector('#cloud').classList.remove('hide', 'show');

          if (arscene) {
            // arscene.showWreckage().then(() => {
            //   document.querySelector('#cloud').classList.add('show');
            // });
            arscene.hideMoonMain().then(() => {
              updateFn = states.hotspots.cloud.update;
              moonParticles.show();
              arscene.showSwarmFromMoon().then(() => {
                sfx.playFx('MF_SX_Window_Generic_Wipe_Open_v1');
                document.querySelector('#cloud').classList.add('show'); // there's some weird sizing stuff with the gleamio iframe embed, and turning it off then on again fixes it

                document.querySelector('#cloud iframe').style.display = 'none';
                window.requestAnimationFrame(() => {
                  document.querySelector('#cloud iframe').style.display = 'block';
                });
                moonParticles.hide();
                resolve();
              });
            }); // arscene.hideMoonMain().then(() => {
            //   console.log('MOON MAIN HIDDEN');
            //   // arscene.showSunCompare().then(() => {
            //   //   console.log('SUN COMPARE IS SHOWN');
            //   //   document.querySelector('#cloud').classList.add('show');
            //   // });
            //   document.querySelector('#cloud').classList.add('show');
            // });
          } else {
            // there's some weird sizing stuff with the gleamio iframe embed, and turning it off then on again fixes it
            document.querySelector('#cloud').classList.add('show');
            window.setTimeout(() => {
              document.querySelector('#cloud iframe').style.display = 'none';
              window.setTimeout(() => {
                document.querySelector('#cloud iframe').style.display = 'block';
              }, 200);
            }, 200);
          }
        });
      },
      hide: function hide() {
        sfx.playFx('MF_SX_Window_Generic_Wipe_Close_v1');
        document.querySelector('#cloud').classList.add('hide');

        if (arscene) {
          arscene.hideSwarmFromMoon().then(() => {
            arscene.showMoonMain().then(() => {
              console.log('MOON MAIN SHOWN');
              states.zoomed.show();
            });
          });
        }
      },
      update: function update() {
        moonParticles.setPosition(arscene.swarmTestMesh.position);
      }
    },
    tickets: {
      getElement: function getElement() {
        states.hotspots.tickets.element = states.hotspots.tickets.element || document.querySelector('[data-content_id=tickets]');
        return states.hotspots.tickets.element;
      },
      show: function show() {
        return new Promise(resolve => {
          sfx.playFx('MF_SX_Tap_Shuttle_01');
          document.querySelector('#tickets').classList.remove('hide', 'show');

          if (arscene) {
            arscene.hideMoonMain().then(() => {
              arscene.showFlyToShuttle().then(() => {
                console.log('MOON MAIN HIDDEN');
                sfx.playFx('MF_SX_Window_Generic_Wipe_Open_v1');
                document.querySelector('#tickets').classList.add('show');
                resolve();
              });
            });
          }
        });
      },
      hide: function hide() {
        sfx.playFx('MF_SX_Window_Generic_Wipe_Close_v1');
        document.querySelector('#tickets').classList.add('hide');

        if (arscene) {
          // arscene.hideSunCompare().then(() => {
          //   console.log('SUN COMPARE IS HIDDEN');
          //   arscene.showMoonMain().then(() => {
          //     console.log('MOON MAIN SHOWN');
          //     states.zoomed.show();
          //   });
          // });
          // arscene.hideWreckage().then(() => {
          //   console.log('bagel IS HIDDEN');
          //   arscene.showMoonMain().then(() => {
          //     console.log('MOON MAIN SHOWN');
          //     states.zoomed.show();
          //   });
          // });
          arscene.hideFlyToShuttle().then(() => {
            arscene.showMoonMain().then(() => {
              console.log('MOON MAIN SHOWN');
              states.zoomed.show();
            });
          }); // arscene.showMoonMain().then(() => {
          //   console.log('MOON MAIN SHOWN');
          //   states.zoomed.show();
          // });
        }
      }
    }
  }
};
var instance;

class AR extends Section {
  constructor() {
    super(myName);
  }

  initDatGui() {
    var gui = new dat.GUI({
      closed: true
    });
    gui.add(this, 'moonFound');
    gui.add(this, 'moonLost');
    gui.add(this, 'moonAcquired');
    gui.add(this, 'moonPlaced');
    var t = 0;
    var dossier_multiplier = Math.random();
    var bagel_multiplier = Math.random();
    var cloud_multiplier = Math.random();
    var tickets_multiplier = Math.random();

    var update = () => {
      this.updateHotspots({
        hotspots: [{
          id: 'dossier',
          center: [100 + (Math.sin(t * dossier_multiplier) + 1) * 0.5 * 100, 100 + (Math.sin(t * dossier_multiplier) + 1) * 0.5 * 100]
        }, {
          id: 'bagel',
          center: [100 + (Math.sin(t * bagel_multiplier) + 1) * 0.5 * 100, 100 + (Math.sin(t * bagel_multiplier) + 1) * 0.5 * 100]
        }, {
          id: 'cloud',
          center: [100 + (Math.sin(t * cloud_multiplier) + 1) * 0.5 * 100, 100 + (Math.sin(t * cloud_multiplier) + 1) * 0.5 * 100]
        }, {
          id: 'tickets',
          center: [100 + (Math.sin(t * cloud_multiplier) + 1) * 0.5 * 100, 100 + (Math.sin(t * tickets_multiplier) + 1) * 0.5 * 100]
        }]
      });
      this.updateMoonTarget({
        width: 40 + 40 * (Math.sin(t * dossier_multiplier) + 1) * 0.5,
        center: [window.innerWidth * 0.5 + (Math.sin(t * dossier_multiplier) + 1) * 0.5 * 100, window.innerHeight * 0.5 + (Math.sin(t * dossier_multiplier) + 1) * 0.5 * 100, 1]
      });
      t += 0.1;
      window.requestAnimationFrame(update);
    };

    var close_button = document.querySelector('#ar_nav .close_button');
    var functions = {
      startUpdate: () => {
        states.hotspots.dossier.getElement().style.top = '0px';
        states.hotspots.dossier.getElement().style.left = '0px';
        states.hotspots.bagel.getElement().style.top = '0px';
        states.hotspots.bagel.getElement().style.left = '0px';
        states.hotspots.cloud.getElement().style.top = '0px';
        states.hotspots.cloud.getElement().style.left = '0px';
        states.finding_moon.getElement().style.top = '0px';
        states.finding_moon.getElement().style.left = '0px';
        update();
      },
      clickHotspot: () => {
        var spot_ids = Object.keys(states.hotspots);
        var pickedSpot = Math.floor(Math.random() * spot_ids.length); // console.log(spot_ids[pickedSpot]);

        this.openHotspot(spot_ids[pickedSpot]);
      },
      toggleDossier: () => {
        var el = document.querySelector('#dossier');

        if (el.classList.contains('hide')) {
          el.classList.remove('hide');
          el.classList.add('show');
          close_button.classList.remove('hide');
          close_button.classList.add('show');
        } else {
          el.classList.remove('show');
          el.classList.add('hide');
          close_button.classList.remove('show');
          close_button.classList.add('hide');
        }
      },
      toggleSizeComparison: () => {
        var el = document.querySelector('#cloud');

        if (el.classList.contains('hide')) {
          el.classList.remove('hide');
          el.classList.add('show');
          close_button.classList.remove('hide');
          close_button.classList.add('show');
        } else {
          el.classList.remove('show');
          el.classList.add('hide');
          close_button.classList.remove('show');
          close_button.classList.add('hide');
        }
      },
      toggleDebris: () => {
        var el = document.querySelector('#bagel');

        if (el.classList.contains('hide')) {
          el.classList.remove('hide');
          el.classList.add('show');
          close_button.classList.remove('hide');
          close_button.classList.add('show');
        } else {
          el.classList.remove('show');
          el.classList.add('hide');
          close_button.classList.remove('show');
          close_button.classList.add('hide');
        }
      }
    };
    gui.add(functions, 'startUpdate');
    gui.add(functions, 'clickHotspot');
    gui.add(functions, 'toggleDossier');
    gui.add(functions, 'toggleSizeComparison');
    gui.add(functions, 'toggleDebris'); // const margin = this.buffermesh.material.uniforms.margin.value;
    // gui.add(dat_gui_settings, 'margin', 0, 1, 0.0001).onChange(() => {
    //   this.buffermesh.material.uniforms.margin.value = dat_gui_settings.margin;
    // });
    // gui.add(dat_gui_settings, 'edge', -1, 1, 0.0001).onChange(() => {
    //   this.buffermesh.material.uniforms.edge.value = dat_gui_settings.edge * (1 + dat_gui_settings.margin);
    // });
  } // eslint-disable-next-line class-methods-use-this


  prepareLoad() {
    var files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  init(callback) {
    if (this.initialized) {
      if (callback) callback();
      return;
    }

    if (app.noar) {
      showparticles = false; // states.landing.show();
      // states.hotspots.cloud.show();
      // states.hotspots.bagel.show();

      states.hotspots.dossier.show(); // window.setTimeout(() => {
      //   document.querySelector('#ar > .rings').classList.add('show');
      // }, 500);
    } else {// arpipeline.events.subscribe('loaded', () => {
      //   const load_wrapper = document.querySelector('#loadImageContainer');
      //   const loader = document.createElement('div');
      //   loader.classList.add('loader');
      //   loader.innerHTML = '<div></div><div></div>';
      //   load_wrapper.appendChild(loader);
      // });
      // arpipeline.events.subscribe('onStart', () => {
      //   window.setTimeout(() => {
      //     states.landing.show();
      //     // document.querySelector('.rings').classList.add('show');
      //   }, 1000);
      // });
      // arpipeline.events.subscribe('getpixels', () => {
      //   this.ctx = this.ctx || arpipeline.sceneVars.renderer.getContext();
      //   findDarkest(this.ctx, (data) => {
      //     const { x, y } = data;
      //     moonParticles.setPosition(new Vector3(x, y, 0.985).unproject(arscene.camera));
      //   });
      // });
    } // document.querySelector('#bagel .top_wrapper a').addEventListener('click', (e) => {
    //   this.closeHotspot();
    // });
    // document.querySelector('#cloud .top_wrapper a').addEventListener('click', (e) => {
    //   this.closeHotspot();
    // });


    if (app.debug || app.noar) this.initDatGui();
    this.moon_arrow = document.querySelector('.arrow_ring');
    window.AR_SECTION = this;
    this.events = pubsub.getInstance();
    super.init();
    this.resize();
    if (!app.noar) arscene = ARScene.getInstance();
    document.querySelector('#shell').addEventListener('click', e => {
      var id = e.target.id;

      if (id) {
        e.preventDefault();

        switch (e.target.id) {
          case 'close_button':
            this.closeHotspot();
            break;

          case 'ar_landing':
            states.landing.hide();
            break;

          case 'back_to_site':
            window.location.href = e.target.href;
            break;

          default:
            console.log('default', e.target.id);
        }
      } else {
        var hotspot_id = e.target.parentElement.dataset.content_id;
        e.target.parentElement.classList.add('visited');

        if (hotspot_id) {
          e.preventDefault();
          this.openHotspot(hotspot_id);
        }
      }
    });

    if (arscene) {
      arscene.init(() => {
        arscene.events.subscribe('update', data => this.update(data));
        arscene.events.subscribe('moonFound', data => this.moonFound(data));
        arscene.events.subscribe('moonLost', data => this.moonLost(data));
        arscene.events.subscribe('moonAcquired', data => this.moonAcquired(data));
        arscene.events.subscribe('calibrationProgress', data => this.updateCalibrationProgress(data));
        arscene.events.subscribe('moonFindPosition', data => this.updateMoonTarget(data));
        arscene.events.subscribe('moonLockComplete', data => this.moonLockComplete(data));
        arscene.events.subscribe('showPlaceMoon', data => this.showPlaceMoon(data));
        arscene.events.subscribe('moonPlaced', data => this.moonPlaced(data));
        arscene.events.subscribe('updateHotspotPositions', data => this.updateHotspots(data));
        arscene.events.subscribe('updateSizeComparison', data => this.updateSizeComparison(data));
        if (callback) callback();
      });
    }
  }

  update(data) {
    // console.log(data.delta);
    if (updateFn) updateFn(data);

    if (showparticles) {
      if (arscene) {
        moonParticles.dirLight.copy(arscene.dirLight.position);
      }

      moonParticles.update(data);
    }
  }

  show(callback) {
    super.show(callback);
    console.log('SHOW AR');
    states.landing.show();
  }

  moonFound() {
    console.log('MOON FOUND');
    sfx.playFx('MF_SX_Moon_Located_Start_01');
    document.querySelector('#moon_target').classList.add('found');
  }

  moonLost() {
    document.querySelector('#moon_target').classList.remove('acquired', 'found');
  }

  moonLockComplete() {
    if (arscene) arscene.hideMoonLock();
    states.finding_moon.hide();
    states.calibrating_moon.hide();
  }

  showPlaceMoon() {
    states.placing_moon.show();
    this.moonLost();
  }

  moonAcquired() {
    calibration_loop = sfx.playFx('MF_SX_Moon_Calibration_Loop_01', true);
    document.querySelector('#ar_find_moon').classList.add('found'); // document.querySelector('#moon_target').classList.add('acquired');

    this.acquiredTimeout = window.setTimeout(() => {
      if (arscene) arscene.hideFindMoon();
      states.finding_moon.moonLocked();
      states.calibrating_moon.show();
      document.querySelector('#ar .rings').classList.remove('show');
      console.log('MOON LOCKED');
    }, 1200);
    console.log('MOON ACQUIRED');
  }

  moonPlaced() {
    states.placing_moon.hide();
    states.zoomed.show();
  }

  openHotspot(spot_id) {
    this.currentSpot = spot_id;
    states.zoomed.hide();
    states.hotspots[spot_id].show.call(this).then(() => {
      var close_button = document.querySelector('#ar_nav .close_button');
      close_button.classList.remove('hide');
      close_button.classList.add('show');
    });
  }

  closeHotspot() {
    var keepCloseButton = states.hotspots[this.currentSpot].hide.call(this);
    if (keepCloseButton) return;
    var close_button = document.querySelector('#ar_nav .close_button');
    close_button.classList.remove('show');
    close_button.classList.add('hide');
    this.currentSpot = null;
  }

  updateCalibrationProgress(_ref) {
    var {
      progress
    } = _ref;
    states.calibrating_moon.getProgressElement().style.transform = "scaleX(".concat(progress, ")");
  }

  updateMoonTarget(_ref2) {
    var {
      width: moon_width,
      center
    } = _ref2;
    var element = states.finding_moon.getElement();
    var target_margin = 0.5 * moon_width;
    var target_width = moon_width + target_margin * 2; // element.style.width = `${width}px`;
    // element.style.height = `${width}px`;

    element.style.setProperty('--target-size', "".concat(target_width, "px"));
    var cx = center[0] * center[2];
    var cy = center[1] * center[2];
    var x = cx - 0.5 * target_width;
    var y = cy - 0.5 * target_width;
    element.style.transform = "translate3d(".concat(x, "px, ").concat(y, "px, 1px)");
    var onscreen = cx + target_width * 0.75 > 0 && cx - target_width * 0.75 < this.winWidth && cy + target_width * 0.75 > 0 && cy - target_width * 0.75 < this.winHeight;
    if (onscreen !== states.finding_moon.onscreen) states.finding_moon.showArrow(onscreen);
    var margin = 60;
    var newx = 0.5 * this.winWidth; // Math.max(margin, Math.min(cx, this.winWidth - margin));

    var newy = 0.5 * this.winHeight; // Math.max(margin, Math.min(cy, this.winHeight - margin));

    var a = cx - newx;
    var o = cy - newy;
    var rad = Math.atan2(o, a);
    var deg = rad * 180 / Math.PI;
    this.moon_arrow.setAttribute('transform', "rotate(".concat(deg + 90, " 50 50)")); // this.moon_arrow.style.transform = `translate3d(${newx}px, ${newy}px, 1px) rotate(${deg}deg)`;
  }

  updateHotspots(_ref3) {
    var {
      hotspots
    } = _ref3;

    for (var i = 0; i < hotspots.length; i++) {
      var spot = hotspots[i];
      var el = states.hotspots[spot.id].getElement();
      var clamped = clamp(0, 1, spot.aligned);
      var mapped = states.hotspots.mapper2(clamped);
      var opacity = states.hotspots.mapper1(clamped);
      el.style.opacity = opacity;
      el.style.transform = "translate3d(".concat(spot.center[0], "px, ").concat(spot.center[1], "px, 1px) scale(").concat(mapped, ", ").concat(mapped, ")");
      el.style.pointerEvents = opacity > 0.2 ? 'all' : 'none';
    } // const pos = arscene.shuttleMesh.getWorldPosition(particlesPosition);
    // if (showparticles) moonParticles.setPosition(pos);

  }

  updateSizeComparison(_ref4) {
    var {
      sun,
      moon
    } = _ref4;
    // console.log('updateSizeComparison', sun, moon);
    var moon_el = states.hotspots.cloud.getMoon();
    var moon_width = states.hotspots.cloud.getMoonWidth();
    moon_el.style.transform = "translate3d(".concat(moon[0] + 10, "px, ").concat(moon[1] - 15, "px, 1px)");
    var sun_el = states.hotspots.cloud.getSun();
    var sun_width = states.hotspots.cloud.getSunWidth();
    sun_el.style.transform = "translate3d(".concat(sun[0] + 10, "px, ").concat(sun[1] - 15, "px, 1px)");
    var min_horiz_line = 10;
    var vert_line_x = Math.max(moon[0] + moon_width + min_horiz_line, sun[0] + sun_width + min_horiz_line);
    var moon_horiz_line = vert_line_x - moon[0] - moon_width;
    var moon_line = moon_el.querySelector('i');
    var vert_line = moon_line.querySelector('b');
    moon_line.style.width = "".concat(moon_horiz_line, "px");
    var sun_horiz_line = vert_line_x - sun[0] - sun_width;
    var sun_line = sun_el.querySelector('i');
    sun_line.style.width = "".concat(sun_horiz_line, "px");
    vert_line.style.height = "".concat(sun[1] - moon[1], "px"); // for (let i = 0; i < hotspots.length; i++) {
    //   const spot = hotspots[i];
    //   const el = states.hotspots[spot.id].getElement();
    //   el.style.transform = `translate3d(${spot.center[0]}px, ${spot.center[1]}px, 1px)`;
    // }
  }

  resize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

}

export default {
  getInstance() {
    instance = instance || new AR();
    return instance;
  }

};