import app from '../global';

class Section {
  constructor(myName) {
    console.log('Section constructor', myName);
    this.name = myName;
  }

  init(callback) {
    console.log('Section init', this.name);
    this.initialized = true;
    this.elements = {};
    this.elements.sectionWrapper = document.getElementById(this.name.toLowerCase());
    if (callback) callback();
  }

  show(callback) {
    console.log(this, "show name ".concat(this.name));
    this.elements.sectionWrapper.classList.add('show');
    if (callback) callback();
  }

  hide(callback) {
    console.log("hide ".concat(this.name));
    this.elements.sectionWrapper.classList.add('hide');
    if (callback) callback();
  }

  startup(callback) {
    console.log("startup ".concat(this.name));
    if (callback) callback();
  }

  shutdown(callback) {
    console.log("shutdown ".concat(this.name));
    this.elements.sectionWrapper.classList.remove('show', 'hide');
    if (callback) callback();
  }

  resize() {}

}

export default Section;