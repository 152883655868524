/* eslint-disable max-len */
import { gsap } from 'gsap/all';

function LoaderUI() {
  this.id = 'LoaderUI'; // this.elem = document.getElementById('loader');
  // this.percentage = document.getElementById('percentage');
}

function init(callback) {
  callback();
}

function goOut() {
  return new Promise(resolve => {
    if (this.elem) this.elem.classList.add('hide');
    resolve();
  });
}

function bringIn() {
  return new Promise(resolve => {
    console.log('BRING IN LOADER');
    if (this.elem) this.elem.classList.add('show');
    resolve();
  });
}

function onProgress(perc) {
  // if (this.prog_el) {
  //   this.prog_el.style.transform = `scaleX(${perc})`;
  // } else {
  //   this.prog_el_fallback.style.width = `${Math.round(perc * 100)}%`;
  // }
  // this.percentage.textContent = `${Math.round(perc * 100)}%`;
  return perc >= 1;
}

function resize() {}

LoaderUI.prototype.resize = resize;
LoaderUI.prototype.onProgress = onProgress;
LoaderUI.prototype.goOut = goOut;
LoaderUI.prototype.bringIn = bringIn;
LoaderUI.prototype.init = init;
export default LoaderUI;