// import {
//   gsap
// } from 'gsap/all';
import SectionLoader from './SectionLoader';
import LoaderUI from './LoaderUI';
import app from '../global';
var sectionLoader = SectionLoader.getInstance();
var curr_loaderID = false;
var perc = 0;
var instance; // let tweenProgress = 0;

var raf;
var images_to_load;
var images_loaded = 0;
var complete_callback;

function Preloader() {
  this.loaderUIObjects = {
    LoaderUI: new LoaderUI()
  };
  sectionLoader.addLoaderUI(this);
}
/**
 * Sets loader ui object
 *
 * @param  {Object} loader_obj
 * @param  {Function} callback
 */


function setLoader(loaderID, callback) {
  console.log('set loader');
  curr_loaderID = loaderID;
  if (callback) callback();
}
/**
 * Call bringIn function on current loaderUI object if exists
 * else animate it in using a default tween
 */


function bringIn(callback) {
  return new Promise(resolve => {
    perc = 0;
    this.finished = false;
    var loaderUI = this.loaderUIObjects[curr_loaderID];
    loaderUI.bringIn().then(() => {
      console.log('BRING IN');
      resolve();
      startTracking.call(this).then(() => {
        console.log('tracking complete');
        if (callback) callback();
      });
    });
  });
}
/**
 * Start loop that tracks load progress
 */


function startTracking() {
  return new Promise(resolve => {
    var loaderUI = this.loaderUIObjects[curr_loaderID];
    /**
     * Get percentage, pass it to loaderUI
     */

    var track = () => {
      console.log('tracking');
      raf = window.requestAnimationFrame(track);
      var newPerc = sectionLoader.getPerc();
      if (Number.isNaN(newPerc) || !Number.isFinite(newPerc)) newPerc = 1;
      perc += Math.ceil(10 * (newPerc - perc) / 0.2) / 1000;
      loaderUI.onProgress(perc);

      if (perc >= 1) {
        window.cancelAnimationFrame(raf);
        goOut.call(this).then(() => isOut(resolve));
      }
    };

    if (app.debug) {
      goOut.call(this).then(() => isOut(resolve));
    } else {
      raf = window.requestAnimationFrame(track);
    }
  });
}
/**
 * Animate out loader
 */


function goOut() {
  return new Promise(resolve => {
    this.loaderUIObjects[curr_loaderID].goOut().then(() => resolve());
  });
}
/**
 * Called when loader has finished animating out
 */


function isOut(callback) {
  console.log('IS OUT');
  if (complete_callback) complete_callback();
  if (callback) callback();
}

function load(images, callback) {
  console.log('LOAD', images, callback);
  images_loaded = 0;
  images_to_load = images;

  if (images_to_load.length === 0) {
    callback();
    return;
  }

  var onload = () => {
    images_loaded++;
  };

  for (var i = 0; i < images_to_load.length; i++) {
    var src = images_to_load[i];
    var img = new Image();
    img.addEventListener('load', onload);
    img.src = src;
  }

  bringIn.call(this, callback); // callback();
}

function complete(callback) {
  complete_callback = callback || false; // // if (!curr_loaderID) isOut();
  // console.log('COMPLETE', callback);
  // if (callback) callback();

  this.finished = true;
}

Preloader.prototype.load = load;
Preloader.prototype.bringIn = bringIn;
Preloader.prototype.setLoader = setLoader;
Preloader.prototype.complete = complete;
var preloader = {
  getInstance: () => {
    instance = instance || new Preloader();
    return instance;
  }
};
export { preloader as default };