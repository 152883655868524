/* eslint-disable no-mixed-operators */

/* eslint-disable prefer-destructuring */

/* eslint-disable class-methods-use-this */
import * as dat from 'dat.gui';
import Section from './Section';
import SectionLoader from '../utils/SectionLoader';
import pubsub from '../utils/pubsub';
import app from '../global';
import MoonParticles from '../widgets/Particles/MoonParticles';
import DesktopScene from '../widgets/DesktopScene';
import sfx from '../utils/sfx'; // import findDarkest from '../widgets/findDarkPixels';

var myName = 'Desktop';
var sectionLoader = SectionLoader.getInstance();
var moonParticles = MoonParticles.getInstance();
var showparticles = true;
var desktopScene = DesktopScene.getInstance();
var instance;

class Desktop extends Section {
  constructor() {
    super(myName);
  }

  initDatGui() {
    var gui = new dat.GUI({
      closed: true
    });
  } // eslint-disable-next-line class-methods-use-this


  prepareLoad() {
    var files = [];

    if (files.length > 0) {
      sectionLoader.addFiles(myName, files);
    }
  }

  init(callback) {
    super.init();
    var container = document.querySelector('#almostthereContainer');
    container.insertAdjacentHTML('afterbegin', '<div class="tt_wrapper_wrapper"><div class="tt_wrapper"><img class="tt" src="/assets/images/tt_desktop.png" alt=""><h1>EXPLORE THE MEGASTRUCTURE</h1></div></div>');
    var link = document.querySelector('.desktop-home-link');
    link.textContent = link.textContent.replace('desktop/', '');
    desktopScene.init(() => {
      callback();
    });
  }

  update(data) {
    if (showparticles) {
      if (desktopScene) {
        moonParticles.dirLight.copy(desktopScene.dirLight.position);
      }

      moonParticles.update(data);
    }
  }

  show(callback) {
    super.show(callback);
    document.querySelector('#camerafeed').classList.add('show');
    document.querySelector('#almostthereContainer').classList.add('show');
  }

  resize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

}

export default {
  getInstance() {
    instance = instance || new Desktop();
    return instance;
  }

};