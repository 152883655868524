/* eslint-disable eqeqeq */

/* eslint-disable func-names */

/* eslint-disable no-param-reassign */

/* eslint-disable max-len */

/* jshint validthis: true */
function Interaction(props) {
  console.log('Interaction');
  this.element = props.element ? props.element : document.body;
  this.onTap = props.onTap ? props.onTap : function () {};
  this.onSwipeUp = props.onSwipeUp ? props.onSwipeUp : function () {};
  this.onSwipeDown = props.onSwipeDown ? props.onSwipeDown : function () {};
  this.onSwipeLeft = props.onSwipeLeft ? props.onSwipeLeft : function () {};
  this.onSwipeRight = props.onSwipeRight ? props.onSwipeRight : function () {};
  this.onDrag = props.onDrag ? props.onDrag : function () {};
  this.onDualDrag = props.onDualDrag ? props.onDualDrag : function () {};
  this.onDown = props.onDown ? props.onDown : function () {};
  this.onMove = props.onMove ? props.onMove : function () {};
  this.onUp = props.onUp ? props.onUp : function () {};
  this.onMouseWheel = props.onMouseWheel ? props.onMouseWheel : null;
  this.onKeyDown = props.onKeyDown ? props.onKeyDown : null;
  this.onKeyUp = props.onKeyUp ? props.onKeyUp : null;
  this.dragState = {
    x: 0,
    y: 0,
    startX: 0,
    startY: 0,
    startTime: 0
  }; // add functions to this, binded to scope

  this.onMouseDown = onMouseDown.bind(this);
  this.onMouseMove = onMouseMove.bind(this);
  this.onMouseUp = onMouseUp.bind(this);
  this.onTouchStart = onTouchStart.bind(this);
  this.onTouchMove = onTouchMove.bind(this);
  this.onTouchEnd = onTouchEnd.bind(this);
  this.down = down.bind(this);
  this.move = move.bind(this);
  this.keyDown = keyDown.bind(this);
  this.keyUp = keyUp.bind(this);
  this.up = up.bind(this);
  this.touches = {};
  this.touchNum = 0;
  this.keyStates = [];
  this.arrows = {
    up: false,
    down: false,
    left: false,
    right: false
  };
  this.cursors = [];
  this.checkCursor = checkCursor.bind(this);
  this.addCursor = addCursor.bind(this);
  this.getCursor = getCursor.bind(this);
  this.removeCursor = removeCursor.bind(this);
  this.isFirstCursor = isFirstCursor.bind(this);
  this.getCursorCount = getCursorCount.bind(this);
  this.getDualDragValues = getDualDragValues.bind(this); // add new listeners
  // addListeners.call(this);
}

Interaction.prototype.destroy = function () {
  // add new listeners
  removeListeners.call(this);
  this.element = null;
  this.onStart = null;
  this.onDrag = null;
  this.onEnd = null;
  this.onMouseDown = null;
  this.onMouseMove = null;
  this.onMouseUp = null;
  this.onTouchStart = null;
  this.onTouchMove = null;
  this.onTouchEnd = null;
  this.down = null;
  this.move = null;
  this.up = null;
};

function addListeners() {
  this.hasListeners = true;

  if (this.onMove) {
    this.element.addEventListener('mousemove', this.onMouseMove);
  }

  this.element.addEventListener('mousedown', this.onMouseDown);
  this.element.addEventListener('touchstart', this.onTouchStart);
  this.element.addEventListener('keydown', this.keyDown);
  this.element.addEventListener('keyup', this.keyUp);
  if (this.onMouseWheel) this.element.addEventListener('wheel', this.onMouseWheel);
}

function removeListeners() {
  this.hasListeners = false;
  this.element.removeEventListener('mousedown', this.onMouseDown);
  this.element.removeEventListener('touchstart', this.onTouchStart);
  this.element.removeEventListener('keydown', this.keyDown);
  this.element.removeEventListener('keyup', this.keyUp); // are drag listeners still in place? remove them

  if (this.mousedrag) removeMouseDrag.call(this);
  if (this.touchdrag) removeTouchDrag.call(this);
  if (this.onMouseWheel) this.element.removeEventListener('wheel', this.onMouseWheel);
  document.body.removeEventListener('mousemove', this.onMouseMove);
}
/* -------------------- MOUSE LISTENERS -------------------- */


function onMouseDown(e) {
  // e.preventDefault();
  if (this.dragging) return;
  this.down(e.clientX, e.clientY, "mouse", e);
  addMouseDrag.call(this);
}

function addMouseDrag() {
  this.mousedrag = true;

  if (!this.onMove) {
    document.body.addEventListener('mousemove', this.onMouseMove);
  }

  document.body.addEventListener('mouseup', this.onMouseUp);
  document.body.addEventListener('mouseleave', this.onMouseUp); // $(document.body).on('mouseleave', this.onMouseUp);
}

function removeMouseDrag() {
  this.mousedrag = false;

  if (!this.onMove) {
    document.body.removeEventListener('mousemove', this.onMouseMove);
  }

  document.body.removeEventListener('mouseup', this.onMouseUp);
  document.body.removeEventListener('mouseleave', this.onMouseUp); // $(document.body).off('mouseleave', this.onMouseUp);
}

function onMouseMove(e) {
  e.preventDefault();
  this.move(e.clientX, e.clientY, "mouse");
}

function onMouseUp(e) {
  e.preventDefault();
  this.up(e.clientX, e.clientY, "mouse");
  removeMouseDrag.call(this);
}
/* -------------------- TOUCH LISTENERS -------------------- */


function onTouchStart(e) {
  this.element.removeEventListener('mousedown', this.onMouseDown); // e.preventDefault();
  // if (this.dragging) return;

  var touches = e.changedTouches;

  for (var t = 0; t < touches.length; t++) {
    var touch = touches[t];
    this.down(touch.pageX, touch.pageY, String(touch.identifier), e);
  }

  if (!this.touchdrag) addTouchDrag.call(this); // e.preventDefault();
}

function addTouchDrag() {
  this.touchdrag = true;
  document.body.addEventListener('touchmove', this.onTouchMove, {
    passive: false
  });
  document.body.addEventListener('touchend', this.onTouchEnd);
  document.body.addEventListener('touchcancel', this.onTouchEnd);
}

function removeTouchDrag() {
  this.touchdrag = false;
  document.body.removeEventListener('touchmove', this.onTouchMove, {
    passive: false
  });
  document.body.removeEventListener('touchend', this.onTouchEnd);
  document.body.removeEventListener('touchcancel', this.onTouchEnd);
}

function onTouchMove(e) {
  var touches = e.changedTouches;

  for (var t = 0; t < touches.length; t++) {
    var touch = touches[t];
    this.move(touch.pageX, touch.pageY, touch.identifier);
  } // const touch = e.touches[0];
  // this.move(touch.pageX, touch.pageY);
  // e.preventDefault();

}

function onTouchEnd(e) {
  // e.preventDefault();
  // const touch = e.changedTouches[0];
  // this.up(touch.pageX, touch.pageY);z
  var touches = e.changedTouches;

  for (var t = 0; t < touches.length; t++) {
    var touch = touches[t];
    this.up(touch.pageX, touch.pageY, touch.identifier);
  }

  if (e.touches.length < 1) removeTouchDrag.call(this); // e.preventDefault();
}
/* -------------------- CURSOR STATES -------------------- */


function checkCursor(id) {
  for (var i = 0; i < this.cursors.length; i++) {
    if (this.cursors[i].id == id) return true;
  }

  return false;
}

function addCursor(x, y, id) {
  this.cursors.push({
    id: id,
    dragState: {
      x: x,
      y: y,
      startX: x,
      startY: y,
      startTime: Date.now(),
      currTime: Date.now(),
      deltaTime: 0,
      maxMove: 0
    }
  });
}

function getCursor(id) {
  for (var i = 0; i < this.cursors.length; i++) {
    if (this.cursors[i].id == id) return this.cursors[i];
  }

  return null;
}

function removeCursor(id) {
  for (var i = 0; i < this.cursors.length; i++) {
    if (this.cursors[i].id == id) {
      this.cursors.splice(i, 1);
      return;
    }
  }
}

function isFirstCursor(id) {
  return this.cursors[0].id == id ? true : false;
}

function getCursorCount() {
  return this.cursors.length;
}

function getDualDragValues() {
  return {
    center: {
      x: (this.cursors[0].x + this.cursors[1].x) / 2,
      y: (this.cursors[0].y + this.cursors[1].y) / 2
    },
    distance: Math.sqrt(Math.pow(this.cursors[0].x - this.cursors[1].x, 2) + Math.pow(this.cursors[0].y - this.cursors[1].y, 2)),
    angle: Math.atan2(this.cursors[0].x - this.cursors[1].x, this.cursors[0].y - this.cursors[1].y)
  };
}
/* -------------------- DRAG FUNCTIONALITY -------------------- */


function down(x, y, id, e) {
  this.moved = false;
  this.dragging = true; // if(!this.touches[id]){
  //   this.touches[id] = true;
  //   this.touchNum++;
  // }

  if (!this.checkCursor(id)) this.addCursor(x, y, id);
  var currTouch = this.getCursor(id);

  if (this.getCursorCount() == 2) {
    var currDualDrag = this.getDualDragValues();
    this.dualDrag = {
      center: {
        start: {
          x: currDualDrag.center.x,
          y: currDualDrag.center.y
        },
        current: {
          x: currDualDrag.center.x,
          y: currDualDrag.center.y
        },
        delta: {
          x: 0,
          y: 0
        }
      },
      distance: {
        start: currDualDrag.distance,
        current: currDualDrag.distance,
        delta: 0
      },
      angle: {
        start: currDualDrag.angle,
        current: currDualDrag.angle,
        delta: 0
      }
    };
  } // this.dragState.x = this.dragState.startX = x;
  // this.dragState.y = this.dragState.startY = y;
  // this.dragState.maxMove = 0;
  // this.dragState.startTime = Date.now();
  // this.dragState.currTime = this.dragState.startTime;
  // this.dragState.deltaTime = 0;
  // if(this.onStart)this.onStart(x,y);


  this.onDown(x, y, e);
}

function move(x, y, id) {
  this.moved = true;
  var currTouch = this.getCursor(id);

  if (currTouch && this.dragging) {
    var prevX = currTouch.dragState.x;
    var prevY = currTouch.dragState.y;
    var now = Date.now();
    currTouch.dragState.deltaTime = now - currTouch.dragState.currTime;
    currTouch.dragState.currTime = now;
    currTouch.dragState.maxMove = Math.max(currTouch.dragState.maxMove, Math.pow(currTouch.dragState.startX - x, 2) + Math.pow(currTouch.dragState.startY - y, 2));
    currTouch.dragState.x = x;
    currTouch.dragState.y = y;

    switch (this.getCursorCount()) {
      case 1:
        this.onDrag(x, y, x - prevX, y - prevY);
        break;

      case 2:
        var currDualDrag = this.getDualDragValues();
        this.dualDrag.center.delta.x = currDualDrag.center.x - this.dualDrag.center.current.x;
        this.dualDrag.center.delta.y = currDualDrag.center.y - this.dualDrag.center.current.y;
        this.dualDrag.center.current.x = currDualDrag.center.x;
        this.dualDrag.center.current.y = currDualDrag.center.y;
        this.dualDrag.distance.delta = currDualDrag.distance - this.dualDrag.distance.current;
        this.dualDrag.distance.current = currDualDrag.distance;
        this.dualDrag.angle.delta = currDualDrag.angle - this.dualDrag.angle.current;
        this.dualDrag.angle.current = currDualDrag.angle;
        this.onDualDrag(this.dualDrag);
        break;

      default:
        break;
    }
  } // if (this.dragging) this.onDrag(x, y, x - this.dragState.x, y - this.dragState.y);


  if (!currTouch || this.isFirstCursor(id)) this.onMove(x, y); // const now = Date.now();
  // this.dragState.deltaTime = now - this.dragState.currTime;
  // this.dragState.currTime = now;
  // this.dragState.maxMove = Math.max(this.dragState.maxMove, Math.pow(this.dragState.startX - x, 2) + Math.pow(this.dragState.startY - y, 2));
  // this.dragState.x = x;
  // this.dragState.y = y;
}

function up(x, y, id) {
  // console.log("up", x, y, id);
  this.dragging = false; // this.touches[id] = false;
  // this.touchNum--;

  var currTouch = this.getCursor(id);
  this.onUp(x, y, {
    distance: currTouch.dragState.maxMove,
    duration: Date.now() - currTouch.dragState.startTime
  }); // this.onUp(x, y, { distance: this.dragState.maxMove, duration: Date.now() - this.dragState.startTime });

  this.removeCursor(id);
}
/* -------------------- KEYBOARD FUNCTIONALITY -------------------- */


function keyDown(e) {
  e = e || window.event;
  if (this.keyStates[e.keyCode] === true) return;
  this.keyStates[e.keyCode] = true;

  if (e.keyCode == '38') {
    // up
    this.arrows.up = true;
  } else if (e.keyCode == '40') {
    // down
    this.arrows.down = true;
  } else if (e.keyCode == '37') {
    // left
    this.arrows.left = true;
  } else if (e.keyCode == '39') {
    // arrow
    this.arrows.right = true;
  }

  if (this.onKeyDown) this.onKeyDown(e.keyCode, this.arrows);
}

function keyUp(e) {
  e = e || window.event;
  if (this.keyStates[e.keyCode] === false) return;
  this.keyStates[e.keyCode] = false;

  if (e.keyCode == '38') {
    // up
    this.arrows.up = false;
  } else if (e.keyCode == '40') {
    // down
    this.arrows.down = false;
  } else if (e.keyCode == '37') {
    // left
    this.arrows.left = false;
  } else if (e.keyCode == '39') {
    // right
    this.arrows.right = false;
  }

  if (this.onKeyUp) this.onKeyUp(e.keyCode, this.arrows);
}

Interaction.prototype.addListeners = addListeners;
Interaction.prototype.removeListeners = removeListeners;
export { Interaction as default };