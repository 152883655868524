import { Color, Vector2, Vector3, Group, Mesh, DirectionalLight, SphereBufferGeometry, PerspectiveCamera, LoadingManager, MeshStandardMaterial, MeshBasicMaterial, ShaderMaterial, TextureLoader, Scene, WebGLRenderer, FrontSide, BackSide, DoubleSide, Quaternion, Euler, CustomBlending, MaxEquation, CubeTextureLoader } from 'three';
import { gsap } from 'gsap/all';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import debounce from '../utils/debounce';
import Interaction from '../utils/Interaction';
import getNamedObjects from '../utils/getNamedObjects';
import moonGlowVert from './shaders/moonGlow-vert.glsl';
import moonGlowFrag from './shaders/moonGlow-frag.glsl';
import moonAlphamaskPars from './shaders/moon-alphamask-pars.glsl';
import moonAlphamaskMain from './shaders/moon-alphamask-main.glsl';
import raysVert from './shaders/rays-vert.glsl';
import raysInnerFrag from './shaders/raysInner-frag.glsl';
import MoonParticles from './Particles/MoonParticles';
var instance;
var resolution = new Vector2();
var moonParticles = MoonParticles.getInstance();
var SCREEN_WIDTH = window.innerWidth;
var SCREEN_HEIGHT = window.innerHeight;
var app = {
  site_path: '/'
};

class DesktopScene {
  constructor() {
    this.initialized = false;
  }

  init(callback) {
    var loadManager = new LoadingManager();
    this.renderer = new WebGLRenderer({
      canvas: document.getElementById('camerafeed')
    });
    this.scene = new Scene(); // this.scene.background = new Color(0x111111);

    this.scene.background = new CubeTextureLoader().setPath("".concat(app.site_path, "assets/images/space/")).load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']);
    this.camera = new PerspectiveCamera(45, 1, 0.1, 1000);
    this.camera.position.z = 2;
    this.dirLight = new DirectionalLight();
    this.scene.add(this.dirLight);
    this.dirLight.position.set(-500, -500, -100);
    this.interactions = new Interaction({
      element: document.body,
      onDown: (x, y) => {
        if (this.onDownFn) this.onDownFn(x, y);
      },
      onUp: (x, y, dragInfo) => {
        if (this.onUpFn) this.onUpFn(x, y, dragInfo);
      },
      onDrag: (x, y, dX, dY) => {
        if (this.onDragFn) this.onDragFn(x, y, dX, dY);
      },
      onMove: (x, y) => {
        if (this.onMoveFn) this.onMoveFn(x, y);
      }
    });
    this.interactions.addListeners();
    this.moonDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonNormalTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-normal.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonAlphaTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/moon-alpha.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonNoiseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/noise.jpg"), tex => {
      tex.flipY = false;
    });
    this.shuttleDiffuseTex = new TextureLoader(loadManager).load("".concat(app.site_path, "assets/models/shuttle-diffuse.jpg"), tex => {
      tex.flipY = false;
    });
    this.moonMat = new MeshStandardMaterial({
      map: this.moonDiffuseTex,
      normalMap: this.moonNormalTex,
      alphaMap: this.moonAlphaTex,
      transparent: true,
      side: FrontSide,
      onBeforeCompile: shader => {
        console.log('onBeforeCompile!', shader);
        shader.uniforms.maskVal = {
          value: 0
        };
        shader.uniforms.maskMap = {
          value: this.moonNoiseTex
        };
        this.moonMatUniforms = shader.uniforms;
        shader.fragmentShader = shader.fragmentShader.replace("#include <clipping_planes_pars_fragment>", "#include <clipping_planes_pars_fragment>\n" + moonAlphamaskPars);
        shader.fragmentShader = shader.fragmentShader.replace("#include <alphamap_fragment>", "#include <alphamap_fragment>\n" + moonAlphamaskMain);
        console.log(moonAlphamaskMain);
        console.log(shader.fragmentShader);
      }
    });
    this.moonMatUnlit = new MeshBasicMaterial({
      map: this.moonDiffuseTex
    });
    this.moonGlowMat = new ShaderMaterial({
      uniforms: {
        maskVal: {
          value: 0
        },
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      vertexShader: moonGlowVert,
      fragmentShader: moonGlowFrag,
      transparent: true,
      side: BackSide
    });
    this.raysInnerMat = new ShaderMaterial({
      uniforms: {
        maskVal: {
          value: 0
        },
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      blending: CustomBlending,
      blendEquation: MaxEquation,
      vertexShader: raysVert,
      fragmentShader: raysInnerFrag,
      transparent: true,
      side: DoubleSide,
      depthWrite: false
    });
    this.raysOuterMat = new ShaderMaterial({
      uniforms: {
        maskVal: {
          value: 0
        },
        maskMap: {
          value: this.moonNoiseTex
        }
      },
      blending: CustomBlending,
      blendEquation: MaxEquation,
      vertexShader: raysVert,
      fragmentShader: raysInnerFrag,
      transparent: true,
      side: DoubleSide,
      depthWrite: false,
      defines: {
        IS_OUTER: true
      }
    });
    this.shuttleMat = new MeshStandardMaterial({
      map: this.shuttleDiffuseTex,
      roughness: 0.5,
      emissiveMap: this.shuttleDiffuseTex,
      emissive: new Color(0x111111)
    });
    this.moonMainGroup = new Group();
    this.moonMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonMat);
    this.moonMesh.renderOrder = 2;
    this.moonInnerMesh = new Mesh(new SphereBufferGeometry(0.5, 32, 32), this.moonGlowMat);
    this.moonInnerMesh.renderOrder = 1;
    this.moonMainGroup.add(this.moonMesh);
    this.moonMainGroup.add(this.moonInnerMesh);
    this.prevTime = Date.now();
    this.currTime = Date.now();
    this.moonMainGroup.rotation.y = -1.15;
    this.moonMainGroup.rotation.x = 1;
    window.arscene = this;
    this.rotationEuler = new Euler();
    this.rotationQuaternion = new Quaternion();
    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/lightRays_v2.glb"), gltf => {
      // console.log('DEBRIS LOADED!', gltf);
      // this.swapMaterials.call(this, gltf.scene);
      var _objects = getNamedObjects(gltf.scene, {}, true);

      this.moonRaysInnerA = _objects['Rays_Inner_A'];
      this.moonRaysInnerB = _objects['Rays_Inner_B'];
      this.moonRaysOuterA = _objects['Rays_Outer_A'];
      this.moonRaysOuterB = _objects['Rays_Outer_B'];
      this.moonRaysInnerA.renderOrder = this.moonRaysInnerB.renderOrder = 3;
      this.moonRaysOuterA.renderOrder = this.moonRaysOuterB.renderOrder = 4;
      this.moonRaysInnerA.material = this.raysInnerMat;
      this.moonRaysInnerB.material = this.raysInnerMat;
      this.moonRaysOuterA.material = this.raysOuterMat;
      this.moonRaysOuterB.material = this.raysOuterMat;
      this.moonMainGroup.add(this.moonRaysInnerA);
      this.moonMainGroup.add(this.moonRaysInnerB);
      this.moonMainGroup.add(this.moonRaysOuterA);
      this.moonMainGroup.add(this.moonRaysOuterB);
    });
    new GLTFLoader(loadManager).load("".concat(app.site_path, "assets/models/shuttle.glb"), gltf => {
      var _objects = getNamedObjects(gltf.scene, {}, true);

      this.shuttleMesh = _objects['shuttle'];
      this.shuttleMesh.material = this.shuttleMat;
      this.moonMainGroup.add(this.shuttleMesh);
      this.shuttleMesh.position.set(0.5, 0, -0.675);
      this.shuttleMesh.scale.setScalar(0.01);
      window.shuttleMesh = this.shuttleMesh;
    });
    window.addEventListener('resize', debounce(this.resizeWindow.bind(this), 100));
    this.resizeWindow(); // this.initBagelAnim();

    loadManager.onLoad = () => {
      moonParticles.start(this.scene, this.renderer, this.camera, () => {
        // moonParticles.hide();
        this.initMoonView();
        this.update();
        window.requestAnimationFrame(() => callback());
      });
    };
  }

  initMoonView() {
    this.currentPos = new Vector2();
    this.targetPos = new Vector2();
    this.scene.add(this.moonMainGroup);
    var m = this.moonMesh.matrixWorld;
    var s = new Vector3().setFromMatrixScale(m).x;
    moonParticles.setParticleScale(0.75 * s);
    var particleSettings = {
      angle: 0,
      particleScale: new Vector3(),
      shuttlePosition: new Vector3(),
      axis: new Vector3(0, 0, 1),
      radius: 0,
      minRadius: 0.05,
      maxRadius: 0.25,
      scale: 1
    };

    var attackShuttle = () => {
      var moonmesh = this.moonMesh;
      if (particleSettings.tl) particleSettings.tl.kill();

      if (moonmesh) {
        var vector = new Vector3(0, 0, 1);
        var tl = particleSettings.tl = gsap.timeline({
          paused: true,
          onUpdate: () => {
            vector.set(0, 0, 1).applyAxisAngle(particleSettings.axis, particleSettings.angle).normalize().multiplyScalar(particleSettings.radius * particleSettings.scale);
            moonParticles.setPosition(vector);
          },
          onComplete: () => {
            attackShuttle();
          }
        });
        tl.to(particleSettings, {
          angle: Math.PI + Math.random() * Math.PI,
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.to(particleSettings.axis, {
          x: Math.random() * 2 - 1,
          y: Math.random() * 2 - 1,
          z: Math.random() * 2 - 1,
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.to(particleSettings, {
          radius: particleSettings.minRadius + Math.random() * (particleSettings.maxRadius - particleSettings.minRadius),
          duration: 0.75 + Math.random() * 1.25
        }, 0);
        tl.play();
      }
    };

    this.stateUpdateFn = (currTime, elapsedTime) => {
      this.rotationEuler.y = 0.002;
      this.rotationQuaternion.setFromEuler(this.rotationEuler);
      this.rotationQuaternion.multiply(this.moonMainGroup.quaternion);
      this.moonMainGroup.quaternion.copy(this.rotationQuaternion);
      this.camera.position.x = this.currentPos.x;
      this.camera.position.y = this.currentPos.y;
      this.camera.lookAt(this.moonMainGroup.position);
      this.currentPos.lerpVectors(this.currentPos, this.targetPos, 0.1); //
      //
      //
      // const settings = states.zoomed.particleSettings;
      // const m = this.moonMesh.matrixWorld;
      // const s = settings.particleScale.setFromMatrixScale(m).x;
      // moonParticles.setParticleScale(0.25 * s);
      // console.log(s);
      // settings.scale = s;

      this.shuttleMesh.getWorldPosition(particleSettings.shuttlePosition);
      moonParticles.mesh.position.copy(particleSettings.shuttlePosition);
      moonParticles.dirLight.set(-this.dirLight.position.x, -this.dirLight.position.y, this.dirLight.position.z);
      moonParticles.update({
        delta: elapsedTime * 0.5
      });
    };

    this.onDragFn = (x, y, dX, dY) => {
      this.rotationEuler.y = dX * 2 / SCREEN_WIDTH;
      this.rotationEuler.x = dY * 2 / SCREEN_WIDTH;
      this.rotationQuaternion.setFromEuler(this.rotationEuler); // this.moonMainGroup.quaternion.multiply(this.rotationQuaternion);

      this.rotationQuaternion.multiply(this.moonMainGroup.quaternion);
      this.moonMainGroup.quaternion.copy(this.rotationQuaternion); // this.moonMainGroup.rotation.y += dX*2 / SCREEN_WIDTH;
      // this.moonMainGroup.rotation.y += dX*2 / SCREEN_WIDTH;
    };

    this.onMoveFn = (x, y) => {
      // this.camera.rotation.y = (1-(x/SCREEN_WIDTH)*2)*0.5;
      this.targetPos.set((1 - x / SCREEN_WIDTH * 2) * 0.5, (1 - y / SCREEN_HEIGHT * 2) * 0.5);
    };

    attackShuttle();
  }

  swapMaterials(Scene) {
    var getmaterial = material => {
      switch (material.name.toLowerCase()) {
        case 'earth':
          return this.earthMat;

        case 'moon':
          return this.moonMatSplitOpen;

        case 'bagel':
          return this.bagelMat;

        default:
          return material;
      }
    };

    var checkChildren = obj => {
      var children = obj.children;

      for (var i = 0; i < children.length; i++) {
        var child = children[i];

        if (child.material) {
          child.material = getmaterial(child.material);
        }

        if (child.materials) for (var m = 0; m < child.materials.length; m++) {
          child.materials[m] = getmaterial(child.materials[m]);
        }
        if (child.children.length) checkChildren(child);
      }
    };

    checkChildren(Scene);
  }

  resizeWindow() {
    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight;
    this.camera.aspect = SCREEN_WIDTH / SCREEN_HEIGHT;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    resolution.set(SCREEN_WIDTH, SCREEN_HEIGHT);
  }

  update() {
    var _now = Date.now();

    var elapsedTime = _now - this.prevTime;
    this.prevTime = this.currTime;
    this.currTime = Date.now();
    if (this.stateUpdateFn) this.stateUpdateFn(this.currTime, elapsedTime);
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.update.bind(this));
  }

}

export default {
  getInstance() {
    instance = instance || new DesktopScene();
    return instance;
  }

};