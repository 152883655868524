export default {
  "src": ["/assets/audio/siteFX.ogg", "/assets/audio/siteFX.m4a", "/assets/audio/siteFX.mp3", "/assets/audio/siteFX.ac3"],
  "sprite": {
    "MF_MX_Drone_Loop_01": [0, 63010.90702947846],
    "MF_SX_Bagel_Moon_FullSeq_01": [65000, 17552.834467120178],
    "MF_SX_Classified_Closed_01": [84000, 1205.6916099773218],
    "MF_SX_Classified_Open_01": [87000, 1880.839002267578],
    "MF_SX_Moon_Calibration_End_01": [90000, 1379.5918367346899],
    "MF_SX_Moon_Calibration_Loop_01": [93000, 2268.6394557823064],
    "MF_SX_Moon_Located_Start_01": [97000, 1857.392290249436],
    "MF_SX_Moon_Wall_Imp_01": [100000, 755.6009070294749],
    "MF_SX_Moon_Wall_Imp_02": [102000, 921.0430839002299],
    "MF_SX_Moon_Wall_Imp_03": [104000, 1028.276643990935],
    "MF_SX_Moon_Wall_Imp_04": [107000, 956.9387755102099],
    "MF_SX_Moon_Wall_Imp_05": [109000, 1000.3401360544188],
    "MF_SX_Moon_Wall_Imp_06": [112000, 934.1269841269906],
    "MF_SX_Moon_Wall_Imp_07": [114000, 859.2290249433177],
    "MF_SX_Moon_Wall_Imp_08": [116000, 1132.8344671201762],
    "MF_SX_Moon_Wall_Imp_09": [119000, 1045.0113378684875],
    "MF_SX_Moon_Wall_Imp_10": [122000, 1276.6439909296992],
    "MF_SX_Place_Moon_01": [125000, 4995.14739229025],
    "MF_SX_Projectile_01": [131000, 7067.5056689342455],
    "MF_SX_Tap_Shuttle_01": [140000, 6098.616780045348],
    "MF_SX_Tap_To_Start_01": [148000, 6406.9387755102125],
    "MF_SX_Window_Generic_Wipe_Close_v1": [156000, 1257.414965986385],
    "MF_SX_Window_Generic_Wipe_Open_v1": [159000, 1399.2517006802814]
  }
};