import loadScript from './loadScript';
import ArrayExecutor from './ArrayExecutor';
import app from '../global';
var instance;
var preload_images = true;
var filesLoadedCallback;
app.verbose = true;
var arrayExecutor = ArrayExecutor(null, 'SectionLoader');
var sectionLoaderState = {
  sections: [],
  currentlyLoadingIDs: [],
  filesToLoad: [],
  filesLoaded: 0,
  loader: null,
  loadedUrls: {},
  files: {}
};
/**
 * Loads a file, calls callback once file is loaded
 * or immediately if file is already loaded and saved in sectionLoaderState
 *
 * @param  {string} url Path to file to load
 * @param  {function} callback
 */

function loadFile(url, callback) {
  console.log(url);

  if (sectionLoaderState.files[url]) {
    callback(sectionLoaderState.files[url]);
  } else {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url);

    xhr.onreadystatechange = () => {
      switch (xhr.readyState) {
        case 0:
          // UNSENT
          // console.log('UNSENT', e.status, e.responseText);
          break;

        case 1:
          // OPENED
          // console.log('OPENED', e.status, e.responseText);
          break;

        case 2:
          // HEADERS_RECEIVED
          // console.log('HEADERS_RECEIVED', e.status, e.responseText);
          break;

        case 3:
          // LOADING
          // console.log('LOADING', e.status, e.responseText);
          break;

        case 4:
          // DONE
          if (xhr.status === 200) {
            sectionLoaderState.files[url] = xhr.responseText;
            callback(xhr.responseText);
          }

          break;

        default:
      }
    };

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
  }
}
/**
 * Returns section object with id that matches passed id
 *
 * @param  {string} id
 * @returns {Object}
 */


function returnSectionOBJ(id) {
  var id_lc = id.toLowerCase();
  var numSections = sectionLoaderState.sections.length;

  while (numSections--) {
    if (sectionLoaderState.sections[numSections].id.toLowerCase() === id_lc) {
      return sectionLoaderState.sections[numSections];
    }
  }

  throw new Error("No section with id: ".concat(id));
}
/**
 * @param  {Object} loaderObj
 */


function addLoaderUI(loaderObj) {
  if (app.verbose) console.log("SectionLoader | addLoaderUI: ".concat(loaderObj));
  sectionLoaderState.loader = loaderObj;
}
/**
 * Adds files to preload for section with id of section_id
 *
 * @param  {string} section_id
 * @param  {Array|string} files
 */


function addFiles(section_id, files) {
  var sectionOBJ = returnSectionOBJ(section_id);
  sectionOBJ.files = sectionOBJ.files || [];

  if (typeof files === 'string') {
    sectionOBJ.files.push(files);
  } else {
    sectionOBJ.files = sectionOBJ.files.concat(files);
  }
}
/**
 * Check to see if section exists
 *
 * @param  {string} id
 * @returns {boolean}
 */


function sectionExists(id) {
  return sectionLoaderState.sections.filter(section => section.id.toLowerCase() === id.toLowerCase()).length > 0;
}
/**
 * Add section to sectionloader
 *
 * @param  {string} id
 * @param  {Object} d
 */


function addSection(id, d) {
  var data = d;
  if (sectionExists(id)) throw new Error("SectionLoader | addSection: section id ".concat(id, " already exists"));
  if (instance.verbose) console.log("SectionLoader | addSection: ".concat(id));
  data.data = data.data || {};
  var sectionObj = {
    id,
    data: data.data,
    loaded: false
  };

  if (data.widgets) {
    sectionObj.widgets = data.widgets;
  }

  if (data.files) {
    sectionObj.files = sectionObj.files || [];
    sectionObj.files = sectionObj.files.concat(data.files);
  }

  sectionLoaderState.sections.push(sectionObj);
}
/**
 * Load sections passed as list of section ids
 *
 * @param  {...*} ...a – List of section ids plus resolve and reject fns passed via arrayexecutor
 */


function loadSection() {
  for (var _len = arguments.length, a = new Array(_len), _key = 0; _key < _len; _key++) {
    a[_key] = arguments[_key];
  }

  var args = a;
  if (app.verbose) console.log('SectionLoader | this.loadSection:', args); // the last 2 args are reject and resolve functions

  args.pop(); // pop the reject function

  var callback = args.pop();
  if (args === undefined || args === null) throw new Error('SectionLoader | this.loadSection: input not valid'); // if 'all' is passed instead of a list of section ids, load all sections

  if (args.length === 1 && args[0] === 'all') {
    args = sectionLoaderState.sections.map(section => section.id);
  } // add a call to initScrape for each section to the array executor


  var function_arr = args.reduce((arr, sectionName) => {
    if (sectionExists(sectionName)) {
      arr.push({
        scope: this,
        fn: this.initScrape,
        vars: [sectionName]
      });
    }

    return arr;
  }, []);
  function_arr.push({
    scope: this,
    fn: this.loadFiles,
    vars: null
  });

  if (callback) {
    function_arr.push({
      fn: callback,
      vars: null
    });
  }

  arrayExecutor.execute(function_arr);
}
/**
 * Scrape section's html for files to preload
 *
 * @param  {string} id
 * @param {function} resolve
 * @param {function} reject
 */


function initScrape(id, resolve, reject) {
  var sectionOBJ = returnSectionOBJ(id); // confirm sectionOBJ was found

  if (sectionOBJ === undefined) reject("SectionLoader | this.loadSection: section id ".concat(id, " not found")); // check if section is already loaded

  if (sectionOBJ.loaded === true) {
    if (app.verbose) console.log("SectionLoader | this.loadSection: ".concat(id, " is already loaded"));
    reject(true);
    return;
  }

  sectionLoaderState.currentlyLoadingIDs.push(sectionOBJ.id);

  if (sectionOBJ.files) {
    sectionLoaderState.filesToLoad = sectionLoaderState.filesToLoad.concat(sectionOBJ.files);
  } // let parser = new DOMParser();
  // let sectionName = sectionOBJ.id === 'Home' ? '' : `${sectionOBJ.id}/`;
  // fetch(`/${sectionName}`).then((resp) => resp.text()).then((html) => {
  //   sectionOBJ.html = parser.parseFromString(html, 'text/html').querySelector('section').outerHTML;
  //   resolve();
  // });


  resolve();
}
/**
 * Check if file has already been loaded
 *
 * @param  {string} fileURL
 */


function isDuplicate(fileURL) {
  var numImages = sectionLoaderState.imagesToLoad.length;

  while (numImages--) {
    if (sectionLoaderState.imagesToLoad[numImages].url === fileURL) {
      return true;
    }
  }

  var numMisc = sectionLoaderState.miscToLoad.length;

  while (numMisc--) {
    if (sectionLoaderState.miscToLoad[numMisc].url === fileURL) {
      return true;
    }
  }

  return false;
}
/**
 * @param  {string} url
 * @param  {function} callback
 */


function loadImage(url, callback) {
  console.log(url);

  if (sectionLoaderState.loadedUrls[url]) {
    if (callback) callback();
    return;
  }

  if (app.verbose) console.log("SectionLoader | load image: ".concat(url));
  sectionLoaderState.loadedUrls[url] = url;
  var newImage = new Image();
  newImage.addEventListener('load', () => {
    if (app.verbose) console.log("SectionLoader | image Loaded: ".concat(url));
    if (callback) callback();
  });
  newImage.addEventListener('error', this.fileError); // newImage.addEventListener('error', () => {
  //   if (callback) callback();
  // });

  newImage.src = url;
}
/**
 * Called when all sections are loaded
 */


function complete() {
  if (app.verbose) console.log('SectionLoader | complete: ');
  var numSectionsLoaded = sectionLoaderState.currentlyLoadingIDs.length;

  while (numSectionsLoaded--) {
    var sectionID = sectionLoaderState.currentlyLoadingIDs[numSectionsLoaded];
    var sectionOBJ = returnSectionOBJ(sectionID);
    sectionOBJ.loaded = true;
  }

  sectionLoaderState.currentlyLoadingIDs = [];
  sectionLoaderState.filesToLoad = [];
  sectionLoaderState.filesLoaded = 0;

  if (sectionLoaderState.loader && !sectionLoaderState.loader.finished) {
    sectionLoaderState.loader.complete(filesLoadedCallback);
  } else {
    filesLoadedCallback();
  }
}
/**
 * Check if all sections have loaded
 */


function checkComplete() {
  if (sectionLoaderState.filesLoaded >= sectionLoaderState.filesToLoad.length) complete();
}
/**
 * Returns a fileLoadComplete function for passed url
 * When the file loads, the url is added to the loaded files object
 *
 * @param  {string} url
 * @return {function} A fileLoadComplete function
 */


function fileLoadComplete(url) {
  return data => {
    if (data) sectionLoaderState.files[url] = data;
    sectionLoaderState.filesLoaded++;
    checkComplete();
  };
}
/**
 * @param  {function} resolve
 * @param  {function} reject
 */


function loadFiles(resolve) {
  filesLoadedCallback = resolve;

  if (sectionLoaderState.filesToLoad.length < 1) {
    if (sectionLoaderState.loader) sectionLoaderState.loader.finished = true;
    this.complete();

    if (app.settings.prepreloader && app.settings.prepreloader.goOut) {
      app.settings.prepreloader.goOut();
    }

    return;
  }

  var i = sectionLoaderState.filesToLoad.length;
  var {
    filesToLoad
  } = sectionLoaderState;

  var startload = () => {
    while (i--) {
      var url = filesToLoad[i];

      if (url.indexOf('.gif') > 0 || url.indexOf('.jpg') > 0 || url.indexOf('.jpeg') > 0 || url.indexOf('.png') > 0) {
        loadImage.call(this, url, fileLoadComplete(url));
      } else if (url.match(/\.js$/)) {
        var jspath = app.settings.jsUrl || '';

        if (sectionLoaderState.loadedUrls[url]) {
          fileLoadComplete(url)();
          return;
        }

        sectionLoaderState.loadedUrls[url] = url;
        loadScript(jspath + url).then(fileLoadComplete(url));
      } else {
        loadFile.call(this, url, fileLoadComplete(url));
      }
    }
  };

  if (sectionLoaderState.loader) {
    sectionLoaderState.loader.bringIn().then(() => startload());
  } else {
    startload();
  }
}
/**
 * @return {number} Percentage of files loaded
 */


function getPerc() {
  var loaded = sectionLoaderState.filesLoaded;
  var totalLoad = sectionLoaderState.filesToLoad.length;

  if (this.assetManager) {
    var assetManagerLoaded = this.assetManager.getLoaded();
    loaded += assetManagerLoaded.loaded;
    totalLoad += assetManagerLoaded.total;
  }

  return loaded / totalLoad;
}
/**
 * @param  {Object} e
 */


function fileError(e) {
  throw new Error("SectionLoader | fileError ".concat(e.path[0].src, " not found"));
}
/**
 * @returns {Object}
 */


function getSectionLoaderState() {
  return sectionLoaderState;
}
/**
 * By default, sectionloader scrapes html for images to preload
 * to skip preloading images, call setPreloadImages(false)
 *
 * @param  {boolean} doPreloadImages
 */


function setPreloadImages(doPreloadImages) {
  preload_images = doPreloadImages;
}

var sectionLoader = {
  verbose: false,
  addLoaderUI,
  addSection,
  sectionExists,
  addFiles,
  loadSection,
  initScrape,
  isDuplicate,
  loadFiles,
  getPerc,
  fileError,
  checkComplete,
  complete,
  returnSectionOBJ,
  getSectionLoaderState,
  loadImage,
  arrayExecutor,
  setPreloadImages
};
export default {
  getInstance() {
    instance = instance || Object.create(sectionLoader);
    return instance;
  }

};