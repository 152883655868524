import { Color, Mesh, ShaderMaterial, DoubleSide, PlaneBufferGeometry, Float32BufferAttribute, BufferGeometry, Vector3, MeshBasicMaterial } from 'three';
import basicVert from './shaders/basic-vert.glsl';
import confettiVert from './shaders/confetti-vert.glsl';
import confettiFrag from './shaders/confetti-frag.glsl';

function createConfetti() {
  // first a single piece
  var confettiPiece = new PlaneBufferGeometry(0.05, 0.1, 1, 1); // now we will create a bunch of copies of that and put it all into
  // a single geometry but with some custom individual value for
  // the vertex shader to use

  var numPieces = 256;
  var combinedGeo = new BufferGeometry();
  var indices = [];
  var vertices = [];
  var uvs = [];
  var normals = [];
  var destPosition = [];
  var destPositionPer = new Vector3();
  var destRotation = [];
  var destRotationPer = new Vector3();
  var colorA = [];
  var colorB = [];
  var confettiColors = [new Color(0xa864fd), new Color(0x29cdff), new Color(0x78ff44), new Color(0xff718d), new Color(0xfdff6a)];

  var randomVal = amp => {
    return amp * (1 - Math.random() * 2);
  };

  var addColor = (index, colorArray) => {
    var col = confettiColors[index];
    colorArray.push(col.r, col.g, col.b);
  };

  var colA, colB;
  var indexLength = confettiPiece.index.array.length;
  console.log(indexLength);
  var startIndex = 0;
  var distance = 0;

  for (var i = 0; i < numPieces; i++) {
    destPositionPer.set(randomVal(1), randomVal(1), randomVal(1));
    destPositionPer.normalize();
    distance = Math.random();
    destPositionPer.x *= 10 * distance;
    destPositionPer.y *= 6 * distance;
    destPositionPer.z *= 6 * distance; // destPositionPer.set(randomVal(10),randomVal(6),randomVal(6));

    destPositionPer.y += 2;
    destRotationPer.set(randomVal(30), randomVal(30), randomVal(30));
    colA = Math.floor(confettiColors.length * Math.random());
    colB = Math.floor(confettiColors.length * Math.random());
    startIndex = i * 4;
    indices.push(startIndex + 0, startIndex + 2, startIndex + 1, startIndex + 2, startIndex + 3, startIndex + 1);

    for (var v = 0; v < 4; v++) {
      addColor(colA, colorA);
      addColor(colB, colorB);
      destPosition.push(destPositionPer.x, destPositionPer.y, destPositionPer.z);
      destRotation.push(destRotationPer.x, destRotationPer.y, destRotationPer.z);
      vertices.push(confettiPiece.attributes.position.array[v * 4 + 0], confettiPiece.attributes.position.array[v * 4 + 1], confettiPiece.attributes.position.array[v * 4 + 2]);
      uvs.push(confettiPiece.attributes.uv.array[v * 4 + 0], confettiPiece.attributes.uv.array[v * 4 + 1]);
      normals.push(confettiPiece.attributes.normal.array[v * 4 + 0], confettiPiece.attributes.normal.array[v * 4 + 1], confettiPiece.attributes.normal.array[v * 4 + 2]);
    }
  }

  combinedGeo.setIndex(indices);
  combinedGeo.setAttribute('position', new Float32BufferAttribute(vertices, 3));
  combinedGeo.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
  combinedGeo.setAttribute('normal', new Float32BufferAttribute(normals, 2));
  combinedGeo.setAttribute('destPosition', new Float32BufferAttribute(destPosition, 3));
  combinedGeo.setAttribute('destRotation', new Float32BufferAttribute(destRotation, 3));
  combinedGeo.setAttribute('colorA', new Float32BufferAttribute(colorA, 3));
  combinedGeo.setAttribute('colorB', new Float32BufferAttribute(colorB, 3));
  var confettiMat = new ShaderMaterial({
    uniforms: {
      progress: {
        value: 0.1
      }
    },
    vertexShader: confettiVert,
    fragmentShader: confettiFrag,
    side: DoubleSide
  });
  var confettiMesh = new Mesh(combinedGeo, confettiMat);
  confettiMesh.rotation.y = Math.PI / 2;
  confettiMesh.frustumCulled = false;
  console.log(combinedGeo);
  console.log(confettiPiece);
  return {
    mesh: confettiMesh,
    material: confettiMat
  };
}

export default {
  get() {
    return createConfetti();
  }

};