function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Scene, PerspectiveCamera, WebGLRenderer, Vector3, Quaternion } from 'three';
import cameraFragShader from './shaders/camera-frag.glsl';
import pubsub from '../utils/pubsub';
import app from '../global'; // this is initially going to be written to use 8th wall but in theory could
// be swapped out with a roll-you-own gyro focused approach
// class should create a threejs scene, renderer, and camera
// the class will handle rendering the camera to the canvas
// but will not render the scene itself in case the site
// needs a more customized render

var instance;
var currTime = Date.now();
var runTime = 0;
var prevTime = Date.now();
var cameraDistortion = 0;

class ARPipeline {
  constructor() {
    _defineProperty(this, "scene3", void 0);

    _defineProperty(this, "engaged", false);

    this.events = pubsub.getInstance();
  }

  init(callback) {
    console.log(this);

    var onxrloaded = () => {
      XRExtras.AlmostThere.configure({
        url: 'https://moonfall.oblio.io/'
      });
      XRExtras.Loading.showLoading({
        onxrloaded: () => {
          this.events.publish('loaded');

          var engage = _ref => {
            var {
              canvas,
              canvasWidth,
              canvasHeight,
              GLctx
            } = _ref;

            if (this.engaged) {
              return;
            }

            var scene = new Scene();
            var camera = new PerspectiveCamera(60.0,
            /* initial field of view; will get set based on device info later. */
            canvasWidth / canvasHeight, 0.01, 100.0);
            scene.add(camera);
            camera.position.set(0, 3, 0);
            var renderer = new WebGLRenderer({
              canvas,
              context: GLctx,
              alpha: false,
              antialias: true
            });
            renderer.autoClear = false;
            renderer.setSize(canvasWidth, canvasHeight);
            this.sceneVars = {
              scene,
              camera,
              renderer,
              cameraDestPos: new Vector3(),
              cameraDestQuat: new Quaternion()
            };
            this.engaged = true;
            XR8.XrController.updateCameraProjectionMatrix({
              origin: camera.position,
              facing: camera.quaternion
            });
            this.events.publish('onStart');
          };

          XR8.addCameraPipelineModules([// Add camera pipeline modules.
          // Existing pipeline modules.
          XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
          {
            name: 'customthreejs',
            onStart: args => {
              if (this.engaged) return;
              engage(args);
              if (callback) callback();
            },
            onAttach: args => {
              if (this.engaged) return;
              engage(args);
              if (callback) callback();
              this.events.publish('onAttach');
            },
            onDetach: () => {
              this.engaged = false;
            },
            onException: err => {
              if (callback) callback();
            },
            onUpdate: this.onUpdate.bind(this),
            onCanvasSizeChange: this.onCanvasSizeChange.bind(this),
            onRender: this.onRender.bind(this),
            xrScene: this.xrScene.bind(this)
          }, XR8.XrController.pipelineModule(), // Enables SLAM tracking.
          XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
          XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
          XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
          XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
          {
            name: 'updateCameraShader',
            onUpdate: this.updateCameraShader
          }]);
          XR8.GlTextureRenderer.configure({
            fragmentSource: cameraFragShader
          }); // Open the camera and start running the camera run loop.

          XR8.run({
            canvas: document.getElementById('camerafeed')
          });
        }
      });
    }; // confirm the 8thwall scripts are ready, if not wait for the event


    if (typeof XR8 !== 'undefined') {
      onxrloaded();
    } else {
      window.addEventListener('xrloaded', onxrloaded);
    }
  }

  onUpdate(_ref2) {
    var {
      frameStartResult,
      processCpuResult,
      processGpuResult
    } = _ref2;
    var realitySource = processCpuResult.reality || processCpuResult.facecontroller;

    if (!realitySource) {
      return;
    }

    var {
      rotation,
      position,
      intrinsics
    } = realitySource;
    var {
      camera,
      cameraDestPos,
      cameraDestQuat
    } = this.sceneVars;

    for (var i = 0; i < 16; i++) {
      camera.projectionMatrix.elements[i] = intrinsics[i];
    } // Fix for broken raycasting in r103 and higher. Related to:
    //   https://github.com/mrdoob/three.js/pull/15996
    // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before setting
    // the inverse


    if (camera.projectionMatrixInverse) {
      if (camera.projectionMatrixInverse.invert) {
        // THREE 123 preferred version
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert();
      } else {
        // Backwards compatible version
        camera.projectionMatrixInverse.getInverse(camera.projectionMatrix);
      }
    } // this.events.publish('onUpdate', {rotation, position, intrinsics});


    if (rotation) {
      cameraDestQuat.copy(rotation); // camera.setRotationFromQuaternion(rotation)
    }

    if (position) {
      cameraDestPos.set(position.x, position.y, position.z); // camera.position.set(position.x, position.y, position.z)
    }
  }

  onCanvasSizeChange(_ref3) {
    var {
      canvasWidth,
      canvasHeight
    } = _ref3;

    if (!this.engaged) {
      return;
    }

    var {
      renderer
    } = this.sceneVars;
    renderer.setSize(canvasWidth, canvasHeight);
  }

  onRender() {
    var {
      scene,
      renderer,
      camera,
      cameraDestPos,
      cameraDestQuat
    } = this.sceneVars;
    currTime = Date.now();
    var elapsed = currTime - prevTime;
    runTime += elapsed / 1000;
    prevTime = currTime;
    camera.quaternion.slerp(cameraDestQuat, 0.25);
    camera.position.lerp(cameraDestPos, 0.25);
    renderer.clearDepth();
    if (!this.paused) this.events.publish('render');
    renderer.render(scene, camera);
  }

  updateCameraShader(_ref4) {
    var {
      frameStartResult,
      processGpuResult
    } = _ref4;

    if (processGpuResult.gltexturerenderer) {
      var {
        shader
      } = processGpuResult.gltexturerenderer;
      var {
        GLctx
      } = frameStartResult;
      var timeLoc = GLctx.getUniformLocation(shader, 'time');
      var dstLoc = GLctx.getUniformLocation(shader, 'distortionAmt');

      if (timeLoc) {
        var p = XR8.GlTextureRenderer.getGLctxParameters(GLctx);
        GLctx.useProgram(shader);
        GLctx.uniform1f(timeLoc, runTime);
        XR8.GlTextureRenderer.setGLctxParameters(GLctx, p);
      }

      if (dstLoc) {
        var _p = XR8.GlTextureRenderer.getGLctxParameters(GLctx);

        GLctx.useProgram(shader);
        GLctx.uniform1f(dstLoc, cameraDistortion);
        XR8.GlTextureRenderer.setGLctxParameters(GLctx, _p);
      }
    }
  }

  setCameraDistortion(amt) {
    cameraDistortion = amt;
  }

  xrScene() {
    return this.sceneVars;
  }

}

export default {
  getInstance() {
    instance = instance || new ARPipeline();
    return instance;
  }

};